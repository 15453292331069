
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJTb3J0ZWQgYnkiLCJTb3J0IEJ5IjoiU29ydCBCeSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJVcnV0IGJlZGFzYXJrYW4iLCJTb3J0IEJ5IjoiVXJ1dCBiZWRhc2Fya2FuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJVcnV0IGJlZGFzYXJrYW4iLCJTb3J0IEJ5IjoiVXJ1dCBiZWRhc2Fya2FuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiLguIjguLHguJTguYDguKPguLXguKLguIfguJXguLLguKEiLCJTb3J0IEJ5Ijoi4LiI4Lix4LiU4LmA4Lij4Li14Lii4LiH4LiV4Liy4LihIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiLguIjguLHguJTguYDguKPguLXguKLguIfguJXguLLguKEiLCJTb3J0IEJ5Ijoi4LiI4Lix4LiU4LmA4Lij4Li14Lii4LiH4LiV4Liy4LihIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJVcnV0IGJlZGFzYXJrYW4iLCJTb3J0IEJ5IjoiVXJ1dCBiZWRhc2Fya2FuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJVcnV0IGJlZGFzYXJrYW4iLCJTb3J0IEJ5IjoiVXJ1dCBiZWRhc2Fya2FuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiLguIjguLHguJTguYDguKPguLXguKLguIfguJXguLLguKEiLCJTb3J0IEJ5Ijoi4LiI4Lix4LiU4LmA4Lij4Li14Lii4LiH4LiV4Liy4LihIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiLguIjguLHguJTguYDguKPguLXguKLguIfguJXguLLguKEiLCJTb3J0IEJ5Ijoi4LiI4Lix4LiU4LmA4Lij4Li14Lii4LiH4LiV4Liy4LihIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJbxaDDtsO2w7bFmeG5r%2BG6veG6veG6vcaMIMOfw73DvcO9XSIsIlNvcnQgQnkiOiJbxaDDtsO2w7bFmeG5ryDhup7DvcO9w71dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3J0ZWQgYnkiOiJbxaDDtsO2w7bFmeG5r%2BG6veG6veG6vcaMIMOfw73DvcO9XSIsIlNvcnQgQnkiOiJbxaDDtsO2w7bFmeG5ryDhup7DvcO9w71dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    