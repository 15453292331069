import type { SearchParams, SearchResultLocationV5 } from '@seek/chalice-types';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectResultsLocation, selectSearchQuery } from 'src/store/selectors';
import type { Country } from 'src/types/globals';

const locationIdsPerCountry: Partial<Record<Country, number[]>> = {
  AU: [3000],
  NZ: [3001],
};

const isBlankSearch = (searchParams: SearchParams | undefined): boolean => {
  if (!searchParams) {
    return true;
  }

  const keys = Object.keys(searchParams);
  return keys.length === 0;
};

const isNoOtherParamsSet = (
  searchParams: SearchParams | undefined,
): boolean => {
  const params = searchParams ? Object.keys(searchParams) : [];
  return (
    params.filter(
      (paramName) => paramName !== 'where' && paramName !== 'whereid',
    ).length === 0
  );
};

const isCurrentCountrySearch = (
  countryCode: Country,
  searchResultsLocation: SearchResultLocationV5 | undefined,
): boolean => {
  if (!searchResultsLocation) {
    return false;
  }

  if (searchResultsLocation.type === 'Country') {
    return Boolean(
      locationIdsPerCountry[countryCode]?.includes(
        Number(searchResultsLocation.whereId),
      ),
    );
  }

  return false;
};

/**
 * Returns a boolean based on whether the given search
 * is for all jobs for the current country (i.e. no other
 * search filters/params applied)
 * @param param0
 * @returns
 */
export const useShouldHideJobCount = () => {
  const searchQueryParams = useSelector(selectSearchQuery);
  const searchResultsLocation = useSelector(selectResultsLocation);
  const { country } = useAppConfig();

  return (
    isBlankSearch(searchQueryParams) ||
    (isCurrentCountrySearch(country, searchResultsLocation) &&
      isNoOtherParamsSet(searchQueryParams))
  );
};
