import { useTranslations } from '@vocab/react';
import { Box, useToast } from 'braid-design-system';
import debounce from 'lodash/debounce';
import {
  type ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useInView } from 'react-intersection-observer';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useCreateSavedSearch } from 'src/modules/hooks/SavedSearch';
import { scrollTo } from 'src/modules/scroll-with-callback';
import { useDispatch, useSelector } from 'src/store/react';
import {
  SAVE_SEARCH_BEGIN,
  SavedSearchStatus,
} from 'src/store/saveSearch/types';
import { selectFeatureFlag } from 'src/store/selectors';

import JobMailWrapper from '../JobMail/JobMailWrapper';

import translations from './.vocab';
import SaveSearchButton from './SaveSearchButton/SaveSearchButton';
import { useToastDetails } from './helper';
import {
  useSaveSearchSectionLoggedIn,
  useSaveSearchSectionLoggedOut,
} from './useSaveSearchSection/useSaveSearchSection';

import * as styles from './SaveSearchSection.css';

const SaveSearchSectionLoggedIn = ({ spaceTop }: SaveSearchSectionProps) => {
  const showToast = useToast();
  const dispatch = useDispatch();
  const saveSearch = useCreateSavedSearch();
  const { isSaved, loading, status, isSearchSaved, savable, errorMessage } =
    useSaveSearchSectionLoggedIn();
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const showFloatingSaveSearch = useSelector(
    selectFeatureFlag('showFloatingSaveSearch'),
  );

  const toastDetails = useToastDetails({
    status,
    statusMessage: errorMessage,
  });

  useEffect(() => {
    if (toastDetails) {
      showToast({
        ...toastDetails,
        message: toastDetails.message,
        key: 'savedSearchesToast',
        data: {
          automation: 'savedSearchesToast',
        },
      });

      if (toastDetails.isError) {
        analyticsFacade.createSaveSearchError({
          message: toastDetails.analyticsMessage,
        });
      }
    }
  }, [toastDetails, showToast, t, analyticsFacade]);

  const onClick = () => {
    dispatch({
      type: SAVE_SEARCH_BEGIN,
      meta: { hotjar: 'Saved Search' },
    });
    saveSearch();
  };

  const [shouldHideButton, setShouldHideButton] = useState(isSearchSaved);
  useEffect(() => {
    setShouldHideButton(status === SavedSearchStatus.UNSAVED && isSearchSaved);
  }, [status, isSearchSaved]);
  return !shouldHideButton && savable ? (
    // This extra copy is to ensure the whole section will animate to disappear
    // after the save button is clicked
    <>
      <Box
        marginTop={spaceTop}
        className={[
          {
            [styles.animationOnEntry]: !isSaved,
            [styles.animationOnExit]: isSaved,
          },
        ]}
      />
      <Box
        className={[
          showFloatingSaveSearch ? styles.stickyWrapper : styles.fixedWrapper,
          {
            [styles.animationOnEntry]: !isSaved,
            [styles.animationOnExit]: isSaved,
          },
        ]}
        data-automation="SaveSearchSectionLoggedIn"
      >
        <SaveSearchButton onClick={onClick} loading={loading || isSaved}>
          {!loading && !isSaved ? t('Save this search') : t('Saving')}
        </SaveSearchButton>
      </Box>
    </>
  ) : (
    <></>
  );
};

const SaveSearchSectionLoggedOut = ({ spaceTop }: SaveSearchSectionProps) => {
  const { t } = useTranslations(translations);
  const { isSaved, loading, savable } = useSaveSearchSectionLoggedOut();
  const analyticsFacade = useAnalyticsFacade();
  const showFloatingSaveSearch = useSelector(
    selectFeatureFlag('showFloatingSaveSearch'),
  );
  const [isHideButton, setIsHideButton] = useState(true);
  const [isTriggeredHideShow, setIsTriggeredHideShow] = useState(false);
  const [wasButtonVisible, setWasButtonVisible] = useState(false);
  const setButtonToHide = () => {
    setIsHideButton(true);
    setIsTriggeredHideShow(true);
  };
  const setButtonToShow = () => {
    setIsHideButton(false);
    setIsTriggeredHideShow(true);
    setWasButtonVisible(true);
  };
  const widgetRef = useRef<HTMLElement>();
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLElement>(null);

  const onChange = useCallback(
    (inView: boolean) => {
      if (!isTriggeredHideShow) {
        const isScrollingBelowElement = widgetRef?.current
          ? widgetRef?.current?.getBoundingClientRect().bottom < 0
          : false;

        if (inView && !isHideButton) {
          setButtonToHide();
        } else if (!inView && isHideButton && !isScrollingBelowElement) {
          setButtonToShow();
        }
      }
    },
    [isTriggeredHideShow, widgetRef, isHideButton],
  );

  const onChangeRef = useRef(onChange);
  useEffect(() => {
    // ref.current will have the latest onChange with access to the latest state and props.
    onChangeRef.current = onChange;
  }, [onChange]);

  const debouncedOnChangeCallback = useMemo(() => {
    // The function will be created only once - on mount
    const onChangeWrapper = (inView: boolean) => {
      // Because ref is mutable, the ref.current is a reference to the latest onChange
      // which has the latest state and props :)
      onChangeRef.current?.(inView);
    };

    // Debounce the onChange that was created once, but now has access to the latest onChange callback.
    // leading: true will ensure the function will be executed as soon as it is called for the first time
    // within a debounce period. Additional calls made during the debounce period will reset the
    // debounce timer, but will not cause immediate execution.
    return debounce(onChangeWrapper, 100, { leading: true });
  }, []);

  const { ref: inViewRef } = useInView({
    onChange: debouncedOnChangeCallback,
  });

  // The following is to make sure the CTA will only hide/show on each static position. It will enable the hide/show again when the user scroll again
  useEffect(() => {
    const resetIsTriggeredHideShow = () => {
      if (isTriggeredHideShow) {
        setIsTriggeredHideShow(false);
      }
    };
    window.addEventListener('scroll', resetIsTriggeredHideShow);

    return () => {
      window.removeEventListener('scroll', resetIsTriggeredHideShow);
    };
  }, [isTriggeredHideShow]);

  // This section is copy from https://github.com/thebuilder/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node: HTMLElement) => {
      // Ref's from useRef needs to have the node assigned to `current`
      widgetRef.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef],
  );

  const onClick = () => {
    analyticsFacade.createUnregisteredSaveSearchClicked({
      jobMailPosition: 'bottom',
      linkText: 'Save this search',
    });

    if (widgetRef.current && buttonRef.current) {
      scrollTo(
        {
          top: widgetRef.current.offsetTop,
          // Consider the height of the button to ensure callback will be called ..
          // .. despite the button animation
          tolerance: buttonRef.current.getBoundingClientRect().height,
        },
        () => {
          inputRef.current?.focus({
            preventScroll: true,
          });
        },
      );
    }
  };

  const shouldShowButton = !isHideButton && !isSaved;
  return savable ? (
    <>
      {showFloatingSaveSearch && (
        <Box
          ref={buttonRef}
          className={[
            styles.stickyWrapper,
            {
              [styles.animationOnEntry]: shouldShowButton,
              [styles.animationOnExit]:
                // To prevent the fist animation when the page is loaded
                wasButtonVisible && !shouldShowButton,
            },
          ]}
        >
          <SaveSearchButton loading={loading} onClick={onClick}>
            {t('Save this search')}
          </SaveSearchButton>
        </Box>
      )}

      <Box
        paddingTop={spaceTop}
        data-automation="SaveSearchSectionLoggedOut"
        ref={setRefs}
      >
        <JobMailWrapper inputRef={inputRef} position="bottom" />
      </Box>
    </>
  ) : null;
};

interface SaveSearchSectionProps {
  spaceTop?: ComponentProps<typeof Box>['paddingTop'];
}

const SaveSearchSection = ({ spaceTop = 'none' }: SaveSearchSectionProps) => {
  const authenticateState = useAuthenticated();

  if (authenticateState === 'pending') {
    return <></>;
  }

  if (authenticateState === 'authenticated') {
    return <SaveSearchSectionLoggedIn spaceTop={spaceTop} />;
  }

  return <SaveSearchSectionLoggedOut spaceTop={spaceTop} />;
};

export default SaveSearchSection;
