// Predicates related to the types field.

const followTypes = ['classification', 'keyword', 'organisation', 'work type'];

export const isFollow = (relatedSearchType: string) =>
  followTypes.find((followType: string) =>
    relatedSearchType.includes(followType),
  ) !== undefined;

export const isCompany = (type?: string) => type?.includes('organisation');
export const isUnknown = (type?: string) => !type || type === '';

export interface RelatedSearchKeyword {
  keywords: string;
  totalJobs: number;
  type?: string;
}
