
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IkluZHVzdHJ5IHJlY29nbmlzZWQgcHJvdmlkZXJzIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJFeHBlcnRzIGluIGNhcmVlciByZWxhdGVkIGVkdWNhdGlvbiIsIlRBRkUiOiJUQUZFIiwiT25saW5lIjoiT25saW5lIiwiQ291cnNlcyBieSBpbmR1c3RyeSI6IkNvdXJzZXMgYnkgaW5kdXN0cnkiLCJFeHBsb3JlIGNvdXJzZXMiOiJFeHBsb3JlIGNvdXJzZXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJLdXJzdXMgcGVyc2lhcGFuIGtlcmphIiwiSW5kdXN0cnkgcmVjb2duaXNlZCBwcm92aWRlcnMiOiJQZW55ZWRpYSBqYXNhIHlhbmcgZGlha3VpIGluZHVzdHJpIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJQYWthciBwZW5kaWRpa2FuIHRlcmthaXQga2FyaXIiLCJUQUZFIjoiVEFGRSIsIk9ubGluZSI6Ik9ubGluZSIsIkNvdXJzZXMgYnkgaW5kdXN0cnkiOiJLdXJzdXMgYmVyZGFzYXJrYW4gaW5kdXN0cmkiLCJFeHBsb3JlIGNvdXJzZXMiOiJKZWxhamFoaSBrdXJzdXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJLdXJzdXMgcGVyc2lhcGFuIGtlcmphIiwiSW5kdXN0cnkgcmVjb2duaXNlZCBwcm92aWRlcnMiOiJQZW55ZWRpYSBqYXNhIHlhbmcgZGlha3VpIGluZHVzdHJpIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJQYWthciBwZW5kaWRpa2FuIHRlcmthaXQga2FyaXIiLCJUQUZFIjoiVEFGRSIsIk9ubGluZSI6Ik9ubGluZSIsIkNvdXJzZXMgYnkgaW5kdXN0cnkiOiJLdXJzdXMgYmVyZGFzYXJrYW4gaW5kdXN0cmkiLCJFeHBsb3JlIGNvdXJzZXMiOiJKZWxhamFoaSBrdXJzdXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiLguKvguKXguLHguIHguKrguLnguJXguKPguJfguLXguYjguIjguLDguJfguLPguYPguKvguYnguITguLjguJPguJ7guKPguYnguK3guKHguKrguLPguKvguKPguLHguJrguIfguLLguJkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IuC4nOC4ueC5ieC5g%2BC4q%2BC5ieC4muC4o%2BC4tOC4geC4suC4o%2BC4l%2BC4teC5iOC5gOC4m%2BC5h%2BC4meC4l%2BC4teC5iOC4ouC4reC4oeC4o%2BC4seC4muC4guC4reC4h%2BC4reC4uOC4leC4quC4suC4q%2BC4geC4o%2BC4o%2BC4oSIsIkV4cGVydHMgaW4gY2FyZWVyIHJlbGF0ZWQgZWR1Y2F0aW9uIjoi4Lic4Li54LmJ4LmA4LiK4Li14LmI4Lii4Lin4LiK4Liy4LiN4LiU4LmJ4Liy4LiZ4LiB4Liy4Lij4Lio4Li24LiB4Lip4Liy4LmA4LiK4Li34LmI4Lit4Lih4LmC4Lii4LiH4Liq4Li54LmI4Lit4Liy4LiK4Li14LieIiwiVEFGRSI6IlRBRkUiLCJPbmxpbmUiOiLguK3guK3guJnguYTguKXguJnguYwiLCJDb3Vyc2VzIGJ5IGluZHVzdHJ5Ijoi4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4Liy4Lih4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiIIiwiRXhwbG9yZSBjb3Vyc2VzIjoi4Liq4Liz4Lij4Lin4LiI4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4LmI4Liy4LiHIOC5hiIsIlNFRUsgTGVhcm5pbmciOiJTRUVLIExlYXJuaW5nIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiLguKvguKXguLHguIHguKrguLnguJXguKPguJfguLXguYjguIjguLDguJfguLPguYPguKvguYnguITguLjguJPguJ7guKPguYnguK3guKHguKrguLPguKvguKPguLHguJrguIfguLLguJkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IuC4nOC4ueC5ieC5g%2BC4q%2BC5ieC4muC4o%2BC4tOC4geC4suC4o%2BC4l%2BC4teC5iOC5gOC4m%2BC5h%2BC4meC4l%2BC4teC5iOC4ouC4reC4oeC4o%2BC4seC4muC4guC4reC4h%2BC4reC4uOC4leC4quC4suC4q%2BC4geC4o%2BC4o%2BC4oSIsIkV4cGVydHMgaW4gY2FyZWVyIHJlbGF0ZWQgZWR1Y2F0aW9uIjoi4Lic4Li54LmJ4LmA4LiK4Li14LmI4Lii4Lin4LiK4Liy4LiN4LiU4LmJ4Liy4LiZ4LiB4Liy4Lij4Lio4Li24LiB4Lip4Liy4LmA4LiK4Li34LmI4Lit4Lih4LmC4Lii4LiH4Liq4Li54LmI4Lit4Liy4LiK4Li14LieIiwiVEFGRSI6IlRBRkUiLCJPbmxpbmUiOiLguK3guK3guJnguYTguKXguJnguYwiLCJDb3Vyc2VzIGJ5IGluZHVzdHJ5Ijoi4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4Liy4Lih4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiIIiwiRXhwbG9yZSBjb3Vyc2VzIjoi4Liq4Liz4Lij4Lin4LiI4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4LmI4Liy4LiHIOC5hiIsIlNFRUsgTGVhcm5pbmciOiJTRUVLIExlYXJuaW5nIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJLdXJzdXMgcGVyc2lhcGFuIGtlcmphIiwiSW5kdXN0cnkgcmVjb2duaXNlZCBwcm92aWRlcnMiOiJQZW55ZWRpYSBqYXNhIHlhbmcgZGlha3VpIGluZHVzdHJpIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJQYWthciBwZW5kaWRpa2FuIHRlcmthaXQga2FyaXIiLCJUQUZFIjoiVEFGRSIsIk9ubGluZSI6Ik9ubGluZSIsIkNvdXJzZXMgYnkgaW5kdXN0cnkiOiJLdXJzdXMgYmVyZGFzYXJrYW4gaW5kdXN0cmkiLCJFeHBsb3JlIGNvdXJzZXMiOiJKZWxhamFoaSBrdXJzdXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJLdXJzdXMgcGVyc2lhcGFuIGtlcmphIiwiSW5kdXN0cnkgcmVjb2duaXNlZCBwcm92aWRlcnMiOiJQZW55ZWRpYSBqYXNhIHlhbmcgZGlha3VpIGluZHVzdHJpIiwiRXhwZXJ0cyBpbiBjYXJlZXIgcmVsYXRlZCBlZHVjYXRpb24iOiJQYWthciBwZW5kaWRpa2FuIHRlcmthaXQga2FyaXIiLCJUQUZFIjoiVEFGRSIsIk9ubGluZSI6Ik9ubGluZSIsIkNvdXJzZXMgYnkgaW5kdXN0cnkiOiJLdXJzdXMgYmVyZGFzYXJrYW4gaW5kdXN0cmkiLCJFeHBsb3JlIGNvdXJzZXMiOiJKZWxhamFoaSBrdXJzdXMiLCJTRUVLIExlYXJuaW5nIjoiU0VFSyBMZWFybmluZyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiLguKvguKXguLHguIHguKrguLnguJXguKPguJfguLXguYjguIjguLDguJfguLPguYPguKvguYnguITguLjguJPguJ7guKPguYnguK3guKHguKrguLPguKvguKPguLHguJrguIfguLLguJkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IuC4nOC4ueC5ieC5g%2BC4q%2BC5ieC4muC4o%2BC4tOC4geC4suC4o%2BC4l%2BC4teC5iOC5gOC4m%2BC5h%2BC4meC4l%2BC4teC5iOC4ouC4reC4oeC4o%2BC4seC4muC4guC4reC4h%2BC4reC4uOC4leC4quC4suC4q%2BC4geC4o%2BC4o%2BC4oSIsIkV4cGVydHMgaW4gY2FyZWVyIHJlbGF0ZWQgZWR1Y2F0aW9uIjoi4Lic4Li54LmJ4LmA4LiK4Li14LmI4Lii4Lin4LiK4Liy4LiN4LiU4LmJ4Liy4LiZ4LiB4Liy4Lij4Lio4Li24LiB4Lip4Liy4LmA4LiK4Li34LmI4Lit4Lih4LmC4Lii4LiH4Liq4Li54LmI4Lit4Liy4LiK4Li14LieIiwiVEFGRSI6IlRBRkUiLCJPbmxpbmUiOiLguK3guK3guJnguYTguKXguJnguYwiLCJDb3Vyc2VzIGJ5IGluZHVzdHJ5Ijoi4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4Liy4Lih4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiIIiwiRXhwbG9yZSBjb3Vyc2VzIjoi4Liq4Liz4Lij4Lin4LiI4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4LmI4Liy4LiHIOC5hiIsIlNFRUsgTGVhcm5pbmciOiJTRUVLIExlYXJuaW5nIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiLguKvguKXguLHguIHguKrguLnguJXguKPguJfguLXguYjguIjguLDguJfguLPguYPguKvguYnguITguLjguJPguJ7guKPguYnguK3guKHguKrguLPguKvguKPguLHguJrguIfguLLguJkiLCJJbmR1c3RyeSByZWNvZ25pc2VkIHByb3ZpZGVycyI6IuC4nOC4ueC5ieC5g%2BC4q%2BC5ieC4muC4o%2BC4tOC4geC4suC4o%2BC4l%2BC4teC5iOC5gOC4m%2BC5h%2BC4meC4l%2BC4teC5iOC4ouC4reC4oeC4o%2BC4seC4muC4guC4reC4h%2BC4reC4uOC4leC4quC4suC4q%2BC4geC4o%2BC4o%2BC4oSIsIkV4cGVydHMgaW4gY2FyZWVyIHJlbGF0ZWQgZWR1Y2F0aW9uIjoi4Lic4Li54LmJ4LmA4LiK4Li14LmI4Lii4Lin4LiK4Liy4LiN4LiU4LmJ4Liy4LiZ4LiB4Liy4Lij4Lio4Li24LiB4Lip4Liy4LmA4LiK4Li34LmI4Lit4Lih4LmC4Lii4LiH4Liq4Li54LmI4Lit4Liy4LiK4Li14LieIiwiVEFGRSI6IlRBRkUiLCJPbmxpbmUiOiLguK3guK3guJnguYTguKXguJnguYwiLCJDb3Vyc2VzIGJ5IGluZHVzdHJ5Ijoi4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4Liy4Lih4Lib4Lij4Liw4LmA4Lig4LiX4LiY4Li44Lij4LiB4Li04LiIIiwiRXhwbG9yZSBjb3Vyc2VzIjoi4Liq4Liz4Lij4Lin4LiI4Lir4Lil4Lix4LiB4Liq4Li54LiV4Lij4LiV4LmI4Liy4LiHIOC5hiIsIlNFRUsgTGVhcm5pbmciOiJTRUVLIExlYXJuaW5nIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJbw4fDtsO2x5rHmsWZxaHhur3hur3FoSDhua%2FhuKnEg8SD4bmvIMSj4bq94bq94bmvIMO9w73DtsO2x5rHmiDEtcO2w7bDny3FmeG6veG6vcSDxIPGjMO9w71dIiwiSW5kdXN0cnkgcmVjb2duaXNlZCBwcm92aWRlcnMiOiJbw4%2FguIHguLXguYnGjMeax5rHmsWh4bmvxZnDvcO9w70gxZnhur3hur3hur3Dp8O2w7bDtsSj4LiB4Li14LmJw6zDrMOsxaHhur3hur3hur3GjCDGpcWZw7bDtsO24bm9w6zDrMOsxozhur3hur3hur3FmcWhXSIsIkV4cGVydHMgaW4gY2FyZWVyIHJlbGF0ZWQgZWR1Y2F0aW9uIjoiW8OL6q2VxqXhur3hur3hur3FmeG5r8WhIMOsw6zDrOC4geC4teC5iSDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxZnhur3hur3hur3GmsSDxIPEg%2BG5r%2BG6veG6veG6vcaMIOG6veG6veG6vcaMx5rHmseaw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYldIiwiVEFGRSI6Ilvhua7huqzGkcOLXSIsIk9ubGluZSI6IlvDluC4geC4teC5icaaw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9XSIsIkNvdXJzZXMgYnkgaW5kdXN0cnkiOiJbw4fDtsO2w7bHmseax5rFmcWh4bq94bq94bq9xaEgw5%2FDvcO9w70gw6zDrMOs4LiB4Li14LmJxozHmseax5rFoeG5r8WZw73DvcO9XSIsIkV4cGxvcmUgY291cnNlcyI6IlvDi%2BqtlcalxprDtsO2w7bFmeG6veG6veG6vSDDp8O2w7bDtseax5rHmsWZxaHhur3hur3hur3FoV0iLCJTRUVLIExlYXJuaW5nIjoiW8Wgw4vDi%2BG4sCDFgeG6veG6veG6vcSDxIPEg8WZ4LiB4Li14LmJw6zDrMOs4LiB4Li14LmJxKNdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3Vyc2VzIHRoYXQgZ2V0IHlvdSBqb2ItcmVhZHkiOiJbw4fDtsO2x5rHmsWZxaHhur3hur3FoSDhua%2FhuKnEg8SD4bmvIMSj4bq94bq94bmvIMO9w73DtsO2x5rHmiDEtcO2w7bDny3FmeG6veG6vcSDxIPGjMO9w71dIiwiSW5kdXN0cnkgcmVjb2duaXNlZCBwcm92aWRlcnMiOiJbw4%2FguIHguLXguYnGjMeax5rHmsWh4bmvxZnDvcO9w70gxZnhur3hur3hur3Dp8O2w7bDtsSj4LiB4Li14LmJw6zDrMOsxaHhur3hur3hur3GjCDGpcWZw7bDtsO24bm9w6zDrMOsxozhur3hur3hur3FmcWhXSIsIkV4cGVydHMgaW4gY2FyZWVyIHJlbGF0ZWQgZWR1Y2F0aW9uIjoiW8OL6q2VxqXhur3hur3hur3FmeG5r8WhIMOsw6zDrOC4geC4teC5iSDDp8SDxIPEg8WZ4bq94bq94bq94bq94bq94bq9xZkgxZnhur3hur3hur3GmsSDxIPEg%2BG5r%2BG6veG6veG6vcaMIOG6veG6veG6vcaMx5rHmseaw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYldIiwiVEFGRSI6Ilvhua7huqzGkcOLXSIsIk9ubGluZSI6IlvDluC4geC4teC5icaaw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9XSIsIkNvdXJzZXMgYnkgaW5kdXN0cnkiOiJbw4fDtsO2w7bHmseax5rFmcWh4bq94bq94bq9xaEgw5%2FDvcO9w70gw6zDrMOs4LiB4Li14LmJxozHmseax5rFoeG5r8WZw73DvcO9XSIsIkV4cGxvcmUgY291cnNlcyI6IlvDi%2BqtlcalxprDtsO2w7bFmeG6veG6veG6vSDDp8O2w7bDtseax5rHmsWZxaHhur3hur3hur3FoV0iLCJTRUVLIExlYXJuaW5nIjoiW8Wgw4vDi%2BG4sCDFgeG6veG6veG6vcSDxIPEg8WZ4LiB4Li14LmJw6zDrMOs4LiB4Li14LmJxKNdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    