import { type ComponentProps, useMemo } from 'react';

import { jobViewModel } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectResultsLocation, selectSearchQuery } from 'src/store/selectors';
import type { JobViewModelV5 } from 'src/types/JobViewModel';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import type { JobListItem } from '../JobListItem';

const useJobViewModel = (job: ComponentProps<typeof JobListItem>['job']) => {
  const location = useSelector(selectResultsLocation);
  const query = useSelector(selectSearchQuery);
  const country = useMelwaysCountry();

  const viewModel: JobViewModelV5 = useMemo(
    () => jobViewModel({ job, query, location }, country),
    [job, query, location, country],
  );
  return viewModel;
};

export default useJobViewModel;
