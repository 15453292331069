import type { SearchResultJobV5, UrlLocation } from '@seek/chalice-types';
import { highlightFromHTML, JobCard } from '@seek/job-card';
import type { JobActionOrigin } from '@seek/seek-jobs-analytics';
import type { SolMetadata } from '@seek/sol-js';
import { useResponsiveValue } from 'braid-design-system';
import { type MouseEvent, memo, useCallback, useEffect, useState } from 'react';
import striptags from 'striptags';

import { useLinkProps } from 'src/components/NavLink/NavLink.tsx';
import { useSplitViewAccessibilityLinks } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useZoneFeatures } from 'src/config/appConfig.ts';
import useSalaryDisplayLabel from 'src/hooks/useSalaryDisplayLabel/useSalaryDisplayLabel';
import { useSaveJobSERP } from 'src/hooks/useSaveJob/useSaveJobSERP.ts';
import { useIsJobViewed } from 'src/hooks/useViewedJobs/useViewedJobs.ts';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useMetricsTimerContext } from 'src/modules/MetricsTimer/MetricsTimerContext.tsx';
import { useEnrichedLocation } from 'src/modules/enriched-location';
import useMeasureTimeFirstMountFromResponseEnd from 'src/modules/hooks/useMeasureTimeFirstMountFromResponseEnd';
import {
  useStopTimerOnComponentVisible,
  useStopTimerOnComponentVisibleControl,
} from 'src/modules/hooks/useStopTimerOnComponentVisible.ts';
import isNewTabEvent from 'src/modules/is-new-tab-event/index.ts';
import { useQualifiedLinkParams } from 'src/modules/qualified-location';
import SolImpressionTracker from 'src/modules/solTracker/SolImpressionTracker';
import { useDispatch, useSelector } from 'src/store/react';
import { setSelectedJobIdAction } from 'src/store/results';
import { jobMetaClick } from 'src/store/search';
import { selectAuthenticated, selectFeatureFlag } from 'src/store/selectors';
import { filterJobTags } from 'src/utils/filterJobTags';
import { mapJobTagsType } from 'src/utils/mapJobTagsType';

import { persistJobUrl } from '../../SplitView/helpers';

import { useClassificationLinks } from './hooks/useClassificationLinks.ts';
import { useCompanyLink } from './hooks/useCompanyLink.ts';
import useJDPNavLinkProps from './hooks/useJDPNavLinkProps';
import { useJobLinkOnClickCallback } from './hooks/useJobLinkOnClickCallback';
import useJobViewModel from './hooks/useJobViewModel';
import { useLocationLinks } from './hooks/useLocationLinks.ts';

interface JobListItemProps {
  position: number;
  isSelected?: boolean;
  job: SearchResultJobV5;
  setCurrentJob?: (job: SearchResultJobV5) => void;
  solMetadata: SolMetadata;
}

const jobUrlWithCardOrigin = ({
  location,
  origin,
}: {
  location: UrlLocation;
  origin: JobActionOrigin;
}) => ({
  ...location,
  query: { ...location.query, origin },
});

function useJobDetailPageLink(
  job: SearchResultJobV5,
  setCurrentJob?: (job: SearchResultJobV5) => void,
) {
  const dispatch = useDispatch();

  const { focusReturnToSearchLink } = useSplitViewAccessibilityLinks();
  const getQualifiedLinkParams = useQualifiedLinkParams({
    shouldUseUrlResolver: true,
  });
  const { location, onBeforeNavigation } = useJDPNavLinkProps(job);
  const isAboveTablet = useResponsiveValue()({
    mobile: false,
    tablet: false,
    desktop: true,
  });

  // This will make sure the URL that push to `history` is the same as the URL on the Job Card's Title
  const jdpEnrichedLocation = useEnrichedLocation({ location });
  const handleClick = (selectedJob: SearchResultJobV5) =>
    isAboveTablet
      ? (e: MouseEvent) => {
          // setCurrentJob is defined only in split view
          if (setCurrentJob) {
            e.preventDefault();

            setCurrentJob(selectedJob);

            const jobId = selectedJob.id;
            // persist Job ID for desktop split view
            persistJobUrl({ job });
            dispatch(setSelectedJobIdAction(jobId));
            focusReturnToSearchLink();
          }
        }
      : undefined;
  const onClick = handleClick(job);

  const getLinkProps = useLinkProps({ getQualifiedLinkParams });
  const jobDetailPageLink = {
    ...getLinkProps(jdpEnrichedLocation),
    onBeforeNavigation,
  };

  const jobDetailPageJobCardOriginLink = {
    ...getLinkProps(
      useEnrichedLocation({
        location: jobUrlWithCardOrigin({ location, origin: 'jobCard' }),
      }),
    ),
  };

  const jobDetailPageCardTitleOriginLink = {
    ...getLinkProps(
      useEnrichedLocation({
        location: jobUrlWithCardOrigin({ location, origin: 'cardTitle' }),
      }),
    ),
  };

  return {
    onClick,
    jobLinkOnClickCallback: useJobLinkOnClickCallback({
      jobDetailPageLink,
      onClick,
    }),
    jobDetailPageLink,
    jobDetailPageJobCardOriginLink,
    jobDetailPageCardTitleOriginLink,
  };
}

const JobListItemWithSolImpressionTracker = ({
  position,
  isSelected,
  job,
  setCurrentJob,
  solMetadata,
}: JobListItemProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const isAuthenticated = useSelector(selectAuthenticated);
  const { SHOW_JOBCARD_TEASER_IN_SPLITVIEW } = useZoneFeatures();
  const behaviouralCuesEnabled = useSelector(
    selectFeatureFlag('behaviouralCues'),
  );
  const remoteSearchFilterEnabled = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );
  const newJobCardDensity = useSelector(selectFeatureFlag('newJobCardDensity'));
  const entryLevelBadge = useSelector(selectFeatureFlag('entryLevelBadge'));
  const serpJobCardInfoDensity1 = useSelector(
    selectFeatureFlag('serpJobCardInfoDensity1'),
  );

  const jobViewModel = useJobViewModel(job);

  const {
    bulletPoints,
    classification,
    currencyLabel,
    id,
    isFeatured,
    listingDate,
    listingDateDescription,
    locationMatch,
    salary,
    srcLogo,
    teaser,
    title,
    unifiedListingDate,
    unifiedListingDateDescription,
    unifiedLocation,
    workType,
    isJobExternal,
  } = jobViewModel;

  const salaryDisplayLabel = useSalaryDisplayLabel({
    salary,
    currencyLabel,
  });

  const {
    jobLinkOnClickCallback,
    jobDetailPageLink,
    jobDetailPageJobCardOriginLink,
    jobDetailPageCardTitleOriginLink,
    onClick,
  } = useJobDetailPageLink(job, setCurrentJob);

  const dispatch = useDispatch();
  const jobMetaLinkOnClickCallback = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      const { type } = e.currentTarget.dataset;
      if (type) {
        dispatch(jobMetaClick(type as Parameters<typeof jobMetaClick>[0]));
      }
    },
    [dispatch],
  );

  const saveJobSERP = useSaveJobSERP({
    jobId: job.id,
    jobTracking: job?.tracking,
  });

  const { isJobViewed, viewJob } = useIsJobViewed(job.id);
  const [isViewed, setIsViewed] = useState(false);
  useEffect(() => {
    const isTagViewedOrAppStartedOrAppFinished = Boolean(
      job?.tags?.some(
        ({ type }) =>
          type === 'VIEWED' ||
          type === 'APP_STARTED' ||
          type === 'APP_FINISHED',
      ),
    );
    setIsViewed(isJobViewed || isTagViewedOrAppStartedOrAppFinished);
  }, [isJobViewed, job?.tags]);

  const metrics = useMetricsTimerContext();
  const jobLinkOnClickHandler = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      jobLinkOnClickCallback(e);
      metrics.startTimer('JobListItemClickToJDV:Visible');

      // Test timer does not check for intersection visibility
      metrics.startTimer('JobListItemClickToJDV:Visible:Control');

      if (!isNewTabEvent(e) && !isSelected) {
        viewJob();
      }
    },

    // metrics context does not need to be in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobLinkOnClickCallback, viewJob, isSelected],
  );
  const hideCompanyLogo = useSelector(selectFeatureFlag('hideCompanyLogo'));

  const onToggleContentHandler = (action: 'collapse' | 'expand') => {
    if (newJobCardDensity) {
      analyticsFacade.jobCardAccordionLinkClicked({
        jobId: id,
        jobListingPosition: position,
        linkAction: action,
      });
    }
  };

  const ref = useStopTimerOnComponentVisible({
    actionName: 'SearchToJobListItem:Visible',
  });
  useMeasureTimeFirstMountFromResponseEnd('JobListItem');
  useStopTimerOnComponentVisibleControl({
    actionName: 'SearchToJobListItem:Visible:Control',
  });

  const filteredJobTags = filterJobTags({
    jobTags: job?.tags,
    isFeatured: job.isFeatured,
    isApplied: saveJobSERP.isApplied,
  });

  const mappedJobTagsType = mapJobTagsType(filteredJobTags);

  return (
    <SolImpressionTracker
      jobId={job.id}
      jobTags={mappedJobTagsType}
      solMetadata={solMetadata}
    >
      <JobCard
        ref={ref}
        listPosition={position}
        aria-label={striptags(title)}
        bulletPoints={bulletPoints}
        companyLink={useCompanyLink({ job })}
        classificationLinks={useClassificationLinks({ classification })}
        id={Number(id)}
        isAuthenticated={isAuthenticated}
        isPremium={isFeatured}
        isSelected={isSelected}
        isViewed={isViewed}
        jobDetailPageLink={jobDetailPageLink}
        jobDetailPageJobCardOriginLink={jobDetailPageJobCardOriginLink}
        jobDetailPageCardTitleOriginLink={jobDetailPageCardTitleOriginLink}
        jobTitle={highlightFromHTML({ html: title })}
        jobLinkOnClick={jobLinkOnClickHandler}
        jobMetaLinkOnClick={jobMetaLinkOnClickCallback}
        listingDate={unifiedListingDate ?? listingDate}
        listingDateDescription={
          unifiedListingDateDescription ?? listingDateDescription
        }
        locationLinks={useLocationLinks({ isJobExternal, unifiedLocation })}
        locationMatch={locationMatch}
        onClick={onClick}
        // TODO: Revisit shouldAddFocusEffect and shouldAddHoverEffect props after cleanup them in metropolis job-card component
        shouldAddFocusEffect={true}
        shouldAddHoverEffect={!isSelected}
        srcLogo={hideCompanyLogo ? undefined : srcLogo}
        salaryDisplayLabel={salaryDisplayLabel}
        saveJob={{
          ...saveJobSERP,
          isApplied:
            saveJobSERP.isApplied ||
            mappedJobTagsType.some((type) => type === 'APP_FINISHED'),
        }}
        tags={filteredJobTags}
        teaser={teaser}
        workType={workType}
        workArrangement={job.workArrangements?.displayText}
        featureFlags={{
          behaviouralCuesEnabled,
          isShowJobCardTeaserInSplitView: SHOW_JOBCARD_TEASER_IN_SPLITVIEW,
          shouldOpenApplyInNewTab: true,
          remoteSearchFilterEnabled,
          jobCardDensityExperiment1HideTeaser: serpJobCardInfoDensity1,
          newJobCardDensity,
          entryLevelBadge,
        }}
        onToggleContent={onToggleContentHandler}
      />
    </SolImpressionTracker>
  );
};

export const JobListItem = memo(JobListItemWithSolImpressionTracker);
