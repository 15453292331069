type Param = string | number | boolean | undefined | null;

export const constructUrl = ({
  pathname,
  query = {},
  hash: hashParam = '',
}: {
  pathname: string;
  query?: Record<string, Param | Param[]>;
  hash?: string;
}) => {
  const params = Object.entries(query).reduce((allParams, [key, value]) => {
    if (value || value === 0) {
      allParams.set(key, String(value));
    }

    return allParams;
  }, new URLSearchParams());

  const qs = params.toString().length ? `?${params.toString()}` : '';

  const hash = hashParam ? `#${hashParam}` : '';

  return `${pathname}${qs}${hash}`;
};
