
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiQ2hvb3NlIHByZWZlcnJlZCBkaXN0YW5jZSIsIlRha2UgbWUgdGhlcmUiOiJUYWtlIG1lIHRoZXJlIiwiQ2hvb3NlIHlvdXIgcHJlZmVycmVkIGRpc3RhbmNlIHN1cnJvdW5kaW5nIHtsb2NhdGlvbn0iOiJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8ifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiUGlsaWggamFyYWsgeWFuZyBkaWluZ2lua2FuLiIsIlRha2UgbWUgdGhlcmUiOiJCYXdhIHNheWEga2Ugc2FuYSIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259IjoiUGlsaWggcHJlZmVyZW5zaSBqYXJhayBkaSBzZWtpdGFyIHtsb2NhdGlvbn0iLCJMb29raW5nIGZvciBzb21ldGhpbmcgbmVhcmJ5PyI6Ik1lbmNhcmkgc2VzdWF0dSBkaSBzZWtpdGFyIGthbXU%2FIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiUGlsaWggamFyYWsgeWFuZyBkaWluZ2lua2FuLiIsIlRha2UgbWUgdGhlcmUiOiJCYXdhIHNheWEga2Ugc2FuYSIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259IjoiUGlsaWggcHJlZmVyZW5zaSBqYXJhayBkaSBzZWtpdGFyIHtsb2NhdGlvbn0iLCJMb29raW5nIGZvciBzb21ldGhpbmcgbmVhcmJ5PyI6Ik1lbmNhcmkgc2VzdWF0dSBkaSBzZWtpdGFyIGthbXU%2FIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiVGFrZSBtZSB0aGVyZSI6IuC4meC4s%2BC4ieC4seC4meC5hOC4m%2BC4q%2BC4meC5ieC4suC4meC4seC5ieC4meC5gOC4peC4oiIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259Ijoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LmC4LiU4Lii4Lij4Lit4LiaIHtsb2NhdGlvbn0g4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8iOiLguIHguLPguKXguLHguIfguKHguK3guIfguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJ7guLfguYnguJnguJfguLXguYjguYPguIHguKXguYnguYDguITguLXguKLguIfguYPguIrguYjguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiVGFrZSBtZSB0aGVyZSI6IuC4meC4s%2BC4ieC4seC4meC5hOC4m%2BC4q%2BC4meC5ieC4suC4meC4seC5ieC4meC5gOC4peC4oiIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259Ijoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LmC4LiU4Lii4Lij4Lit4LiaIHtsb2NhdGlvbn0g4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8iOiLguIHguLPguKXguLHguIfguKHguK3guIfguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJ7guLfguYnguJnguJfguLXguYjguYPguIHguKXguYnguYDguITguLXguKLguIfguYPguIrguYjguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiUGlsaWggamFyYWsgeWFuZyBkaWluZ2lua2FuLiIsIlRha2UgbWUgdGhlcmUiOiJCYXdhIHNheWEga2Ugc2FuYSIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259IjoiUGlsaWggcHJlZmVyZW5zaSBqYXJhayBkaSBzZWtpdGFyIHtsb2NhdGlvbn0iLCJMb29raW5nIGZvciBzb21ldGhpbmcgbmVhcmJ5PyI6Ik1lbmNhcmkgc2VzdWF0dSBkaSBzZWtpdGFyIGthbXU%2FIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiUGlsaWggamFyYWsgeWFuZyBkaWluZ2lua2FuLiIsIlRha2UgbWUgdGhlcmUiOiJCYXdhIHNheWEga2Ugc2FuYSIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259IjoiUGlsaWggcHJlZmVyZW5zaSBqYXJhayBkaSBzZWtpdGFyIHtsb2NhdGlvbn0iLCJMb29raW5nIGZvciBzb21ldGhpbmcgbmVhcmJ5PyI6Ik1lbmNhcmkgc2VzdWF0dSBkaSBzZWtpdGFyIGthbXU%2FIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiVGFrZSBtZSB0aGVyZSI6IuC4meC4s%2BC4ieC4seC4meC5hOC4m%2BC4q%2BC4meC5ieC4suC4meC4seC5ieC4meC5gOC4peC4oiIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259Ijoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LmC4LiU4Lii4Lij4Lit4LiaIHtsb2NhdGlvbn0g4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8iOiLguIHguLPguKXguLHguIfguKHguK3guIfguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJ7guLfguYnguJnguJfguLXguYjguYPguIHguKXguYnguYDguITguLXguKLguIfguYPguIrguYjguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiVGFrZSBtZSB0aGVyZSI6IuC4meC4s%2BC4ieC4seC4meC5hOC4m%2BC4q%2BC4meC5ieC4suC4meC4seC5ieC4meC5gOC4peC4oiIsIkNob29zZSB5b3VyIHByZWZlcnJlZCBkaXN0YW5jZSBzdXJyb3VuZGluZyB7bG9jYXRpb259Ijoi4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4Liw4LiX4Liy4LiH4LmC4LiU4Lii4Lij4Lit4LiaIHtsb2NhdGlvbn0g4LiX4Li14LmI4LiV4LmJ4Lit4LiH4LiB4Liy4LijIiwiTG9va2luZyBmb3Igc29tZXRoaW5nIG5lYXJieT8iOiLguIHguLPguKXguLHguIfguKHguK3guIfguKvguLLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYPguJnguJ7guLfguYnguJnguJfguLXguYjguYPguIHguKXguYnguYDguITguLXguKLguIfguYPguIrguYjguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiW8OH4bipw7bDtsO2w7bDtsO2xaHhur3hur3hur0gxqXFmeG6veG6veG6vcaS4bq94bq94bq9xZnFmeG6veG6veG6vcaMIMaMw6zDrMOsxaHhua%2FEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vV0iLCJUYWtlIG1lIHRoZXJlIjoiW%2BG5rsSDxIPEg8S34bq94bq94bq9IG3MguG6veG6veG6vSDhua%2FhuKnhur3hur3hur3FmeG6veG6veG6vV0iLCJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSI6IlvDh%2BG4qcO2w7bDtsO2w7bDtsWh4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnhur3hur3hur3GkuG6veG6veG6vcWZxZnhur3hur3hur3GjCDGjMOsw6zDrMWh4bmvxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur0gxaHHmseax5rFmcWZw7bDtsO2x5rHmsea4LiB4Li14LmJxozDrMOsw6zguIHguLXguYnEoyB7bG9jYXRpb259XSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiW8WBw7bDtsO2w7bDtsO2xLfDrMOsw6zguIHguLXguYnEoyDGksO2w7bDtsWZIMWhw7bDtsO2bcyC4bq94bq94bq94bmv4bipw6zDrMOs4LiB4Li14LmJxKMg4LiB4Li14LmJ4bq94bq94bq9xIPEg8SDxZnDn8O9w73DvT9dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgcHJlZmVycmVkIGRpc3RhbmNlIjoiW8OH4bipw7bDtsO2w7bDtsO2xaHhur3hur3hur0gxqXFmeG6veG6veG6vcaS4bq94bq94bq9xZnFmeG6veG6veG6vcaMIMaMw6zDrMOsxaHhua%2FEg8SDxIPguIHguLXguYnDp%2BG6veG6veG6vV0iLCJUYWtlIG1lIHRoZXJlIjoiW%2BG5rsSDxIPEg8S34bq94bq94bq9IG3MguG6veG6veG6vSDhua%2FhuKnhur3hur3hur3FmeG6veG6veG6vV0iLCJDaG9vc2UgeW91ciBwcmVmZXJyZWQgZGlzdGFuY2Ugc3Vycm91bmRpbmcge2xvY2F0aW9ufSI6IlvDh%2BG4qcO2w7bDtsO2w7bDtsWh4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnhur3hur3hur3GkuG6veG6veG6vcWZxZnhur3hur3hur3GjCDGjMOsw6zDrMWh4bmvxIPEg8SD4LiB4Li14LmJw6fhur3hur3hur0gxaHHmseax5rFmcWZw7bDtsO2x5rHmsea4LiB4Li14LmJxozDrMOsw6zguIHguLXguYnEoyB7bG9jYXRpb259XSIsIkxvb2tpbmcgZm9yIHNvbWV0aGluZyBuZWFyYnk%2FIjoiW8WBw7bDtsO2w7bDtsO2xLfDrMOsw6zguIHguLXguYnEoyDGksO2w7bDtsWZIMWhw7bDtsO2bcyC4bq94bq94bq94bmv4bipw6zDrMOs4LiB4Li14LmJxKMg4LiB4Li14LmJ4bq94bq94bq9xIPEg8SDxZnDn8O9w73DvT9dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    