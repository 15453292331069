import { metrics } from '@seek/metrics-js';
import { Box } from 'braid-design-system';
import { useEffect } from 'react';

import JobDetailError from 'src/components/JobDetailError/JobDetailError';
import JobDetailsPageView from 'src/components/JobDetailsPage/JobDetailsPage';
import PageLoader from 'src/components/PageLoader/PageLoader';
import { logger } from 'src/modules/logger';
import type { JobDetails } from 'src/store/jobdetails/types';

import SplitViewInitialView from '../SplitViewInitialView/SplitViewInitialView';

import { JobExpired } from './JobExpired';
import useJobDetail from './useJobDetail';
import { useTrackJobDetailsLoaded } from './useTrackJobDetailsLoaded';

const JobDetail = ({
  currentJobDetails,
  fetchJobDetails: onReloadClick,
  isLoading,
}: {
  currentJobDetails: JobDetails | null;
  fetchJobDetails: () => void;
  isLoading?: boolean;
}) => {
  const {
    isError,
    isExpired,
    hasJobId,
    autoSelectSplitViewFirstJob,
    ipAddress,
    userAgent,
  } = useJobDetail();
  const { isJobDetailsTrackingReadyToCall, trackJobDetailsLoaded } =
    useTrackJobDetailsLoaded({
      isLoading,
      isError,
      isExpired,
    });

  useEffect(() => {
    if (isJobDetailsTrackingReadyToCall) {
      trackJobDetailsLoaded();
    }

    if (isExpired) {
      logger.info(
        {
          input: {
            jobId: currentJobDetails?.job.id,
            status: currentJobDetails?.job.status,
            ipAddress,
            userAgent,
          },
        },
        'Job is expired',
      );
    }
  }, [
    currentJobDetails,
    ipAddress,
    isExpired,
    isJobDetailsTrackingReadyToCall,
    trackJobDetailsLoaded,
    userAgent,
  ]);

  const initialView = (
    <Box display="block" height="full">
      <SplitViewInitialView />
    </Box>
  );

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError) {
    metrics.count('ui_error', [`type:job_details`]);
    return <JobDetailError onReloadClick={onReloadClick} />;
  }

  if (isExpired) {
    return <JobExpired />;
  }

  if (currentJobDetails) {
    return (
      <JobDetailsPageView jobDetails={currentJobDetails} embedded={true} />
    );
  }

  if (autoSelectSplitViewFirstJob || hasJobId) {
    return <PageLoader />;
  }

  return initialView;
};

export default JobDetail;
