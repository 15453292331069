import BLANKS_BLACKLIST from './keyword-follow-rules-blanks-blacklist';
import { isFollow, isUnknown } from './types';

export const REL_FOLLOW = null;
export const REL_NOFOLLOW = 'nofollow';

export const shouldFollow = (keyword: string, type: string) =>
  isFollow(type) ||
  (isUnknown(type) && keyword?.includes('not for profit')) ||
  (isUnknown(type) &&
    !BLANKS_BLACKLIST[keyword] &&
    !/\b(not|or)\b/.test(keyword));

export const linkRelForKeyword = (
  keyword?: string | null,
  type?: string | null,
) =>
  shouldFollow(keyword?.toLowerCase() || '', type?.toLowerCase() || '')
    ? REL_FOLLOW
    : REL_NOFOLLOW;
