import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from '@apollo/client';
import { metrics } from '@seek/metrics-js';
import { getSolSearchDataProp } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import { Box, PageBlock } from 'braid-design-system';
import clsx from 'clsx';
import { Fragment, useEffect } from 'react';

import StickySerpTarget from 'src/components/StickySerpTarget/StickySerpTarget';
import { useAppConfig } from 'src/config/appConfig';
import { HomePage } from 'src/handlers/Home';
import { JobDetailsPageView } from 'src/handlers/JobDetails';
import {
  PageTitle,
  useSetHubblePage,
  useSetHubbleTags,
} from 'src/modules/hubble';
import { shouldRenderPOEA } from 'src/modules/seo-header/search-results-page/utils';
import { fetchSalaryNudge } from 'src/store/nudges';
import { useDispatch, useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectCompanySuggestion,
  selectIsAuthenticationResolved,
  selectIsRadialFilterShown,
  selectIsResultsHidden,
  selectIsResultsInError,
  selectIsResultsLoading,
  selectResults,
  selectSolMetadataString,
  selectUserTestHeaders,
} from 'src/store/selectors';

import POEAHeading from '../Poea/PoeaHeading';

import translations from './.vocab';
import ResultsError from './ResultsError/ResultsError';
import SearchResultsHeaders from './SearchResultHeaders/SearchResultsHeaders';
import SeoFooter from './SeoFooter/SeoFooter';
import { SkyScraperBanner } from './SplitView/SkyScraperBanner/SkyScraperBanner';
import SplitView from './SplitView/SplitView';

import * as styles from './SearchResultsPage.css';

const getRootClasses = (hideResults: boolean) =>
  clsx({
    [styles.root]: true,
    [styles.root_areResultsHidden]: hideResults,
  });

const SearchResultPage = () => {
  useSetHubblePage(PageTitle.searchResults);
  const testHeaders = useSelector(selectUserTestHeaders);
  useSetHubbleTags(testHeaders);
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  const { t } = useTranslations(translations);
  const searchResultsViewModel = useSelector(selectResults);
  const isLoading = useSelector(selectIsResultsLoading);
  const resultsHidden = useSelector(selectIsResultsHidden);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const solMetadataString = useSelector(selectSolMetadataString) || '';
  const isError = useSelector(selectIsResultsInError);
  const shouldShowDistanceFrom = useSelector(selectIsRadialFilterShown);
  const {
    isZeroResults = false,
    searchParams,
    jobs,
  } = searchResultsViewModel || {};
  const { header, hasHeaderBeforeJobs, locationSuggestions } =
    searchResultsViewModel || {};

  const shouldShowDistanceHeader =
    hasHeaderBeforeJobs || shouldShowDistanceFrom;

  const { zone, zoneFeatures } = useAppConfig();

  const hasAuthenticationFetched = useSelector(selectIsAuthenticationResolved);
  const isAuthenticated = useSelector(selectAuthenticated);
  const { NUDGE_ENABLED = false } = zoneFeatures ?? {};

  useEffect(() => {
    JobDetailsPageView.preload();
    HomePage.preload();
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (NUDGE_ENABLED && hasAuthenticationFetched && isAuthenticated) {
      dispatch(fetchSalaryNudge({ apolloClient, zone }));
    }
  }, [
    isAuthenticated,
    hasAuthenticationFetched,
    NUDGE_ENABLED,
    apolloClient,
    zone,
    dispatch,
  ]);

  const renderPOEA = () =>
    shouldRenderPOEA({ zone, keywords: searchParams?.keywords });

  const renderSplitView = () => (
    <Fragment>
      <Box data-automation="searchResults" height="full">
        {renderPOEA() && (
          <POEAHeading
            keyword={searchParams?.keywords || ''}
            location={searchParams?.where}
          />
        )}
        <SplitView
          companySuggestion={companySuggestion}
          isZeroResults={isZeroResults}
          jobs={isLoading ? [] : jobs || []}
          resultSummary={
            <SearchResultsHeaders
              {...{
                shouldShowDistanceHeader,
                header,
                locationSuggestions,
              }}
            />
          }
          isCompanySearch={Boolean(searchParams && searchParams.companysearch)}
        />
      </Box>
    </Fragment>
  );

  useEffect(() => {
    if (isError) {
      metrics.count('ui_error', [`type:search_results`]);
    }
  }, [isError]);

  if (resultsHidden) {
    return (
      <section className={getRootClasses(resultsHidden)}>
        <span
          data-automation="hiddenSearchResults"
          {...getSolSearchDataProp(solMetadataString)}
        />
      </section>
    );
  }

  const pageBlockWidth = 'large';

  return (
    <StickySerpTarget
      component="section"
      role="region"
      aria-label={t('Search Results')}
      className={getRootClasses(isLoading)}
      name="sticky-save-search-desktop"
    >
      {/* SeoFooter needs to be high in the DOM for google */}
      <PageBlock width="large">
        <SeoFooter key="seo-footer" />
      </PageBlock>

      {isError ? (
        <PageBlock width={pageBlockWidth}>
          <Box display="flex">
            <Box width="full" className={styles.content} paddingTop="xxlarge">
              <ResultsError />
            </Box>
          </Box>
        </PageBlock>
      ) : (
        <PageBlock width={pageBlockWidth}>
          <Box position="relative">
            {renderSplitView()}
            <SkyScraperBanner />
          </Box>
        </PageBlock>
      )}
    </StickySerpTarget>
  );
};
export default SearchResultPage;
