import type { SearchParams } from '@seek/chalice-types';
import {
  type Classification,
  top as classificationsTree,
} from '@seek/classification-data';

import { type RelatedSearchKeyword, isCompany } from './types';

const WORK_TYPES_MAP: Record<string, number> = {
  fulltime: 242,
  parttime: 243,
  contract: 244,
  casual: 245,
};

const filterSubClassifications =
  (searchPredicate: RegExp) => (item: Classification) =>
    searchPredicate.test(item.name());

const isEqual = (comparisonValue: string) => (value: string) =>
  value === comparisonValue;

const toLowerCase = (value: string) => value?.toLowerCase() || '';

const isAWorkType = (keywordAsKey: string) =>
  Object.keys(WORK_TYPES_MAP).some(isEqual(keywordAsKey));

const removeAllWhiteSpaceFrom = (keyword: string) =>
  keyword.trim().replace(/ +/, '');

const translateKeywordsToACompany = (
  relatedSearchKeyword: RelatedSearchKeyword,
): SearchParams | null | undefined =>
  isCompany(relatedSearchKeyword.type)
    ? { keywords: relatedSearchKeyword.keywords }
    : null;

const translateKeywordsToWorkTypes = (
  relatedSearchKeyword: RelatedSearchKeyword,
): SearchParams | null | undefined => {
  const { keywords } = relatedSearchKeyword;
  const keywordAsKey = removeAllWhiteSpaceFrom(toLowerCase(keywords));

  if (keywords.length && isAWorkType(keywordAsKey)) {
    return { worktype: String(WORK_TYPES_MAP[keywordAsKey]) };
  }

  return null;
};

const translateKeywordsToClassifications = (
  relatedSearchKeyword: RelatedSearchKeyword,
): SearchParams | null | undefined => {
  const { keywords } = relatedSearchKeyword;

  if (!keywords || keywords.length <= 3) {
    return null;
  }

  const keywordWordCount = keywords.trim().replace(/ +/, ' ').split(' ').length;

  try {
    const searchPredicate = new RegExp(`\^${keywords}$`, 'i');
    const classMatches = classificationsTree.filter((item) =>
      searchPredicate.test(item.name()),
    );

    if (classMatches.length === 1) {
      return { classification: String(classMatches[0].shortId) };
    }

    if (classMatches.length === 0 && keywordWordCount > 1) {
      // If the keyword is greater than 1 word, and we haven't
      // had a classification match

      const subMatches = classificationsTree
        .map(({ shortId, subClassifications }) => [
          shortId,
          subClassifications?.filter(
            filterSubClassifications(searchPredicate),
          ) ?? [],
        ])
        .filter((v) => Array.isArray(v[1]) && v[1].length > 0);

      if (subMatches.length) {
        return {
          classification: String(subMatches[0][0]),
          // @ts-expect-error Object is possibly undefined
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          subclassification: String(subMatches[0][1][0].shortId),
        };
      }
    }

    return null; // no match, or too many matches.
  } catch {
    return null;
  }
};

const makeKeywordsConsistentlyCased = (sp: SearchParams): SearchParams =>
  sp.keywords ? { ...sp, keywords: String(sp.keywords).toLowerCase() } : sp;

const qualifyRelatedSearch = (
  relatedSearchKeyword: RelatedSearchKeyword,
): SearchParams =>
  translateKeywordsToACompany(relatedSearchKeyword) ??
  makeKeywordsConsistentlyCased(
    translateKeywordsToClassifications(relatedSearchKeyword) ??
      translateKeywordsToWorkTypes(relatedSearchKeyword) ?? {
        keywords: relatedSearchKeyword.keywords,
      },
  );

export default qualifyRelatedSearch;
