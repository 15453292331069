import { useTranslations } from '@vocab/react';
import { Box, IconChevron, Inline, Text, TextLink } from 'braid-design-system';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useDispatch, useSelector } from 'src/store/react';
import { showPoeaCountryPickerFromNavigation } from 'src/store/search';
import { selectPathname } from 'src/store/selectors';

import translations from './.vocab';

interface POEAHeadingProps {
  keyword: string;
  location?: string;
}

const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const POEAHeading = ({ keyword, location }: POEAHeadingProps) => {
  const { t } = useTranslations(translations);
  const currentPath = useSelector(selectPathname);
  const newPathWithoutLocation = currentPath.replace(/\/in-[^\/]+/, '');
  const dispatch = useDispatch();
  const filteredKeyword = keyword?.replace(/jobs?/gi, '').trim();

  const analyticsFacade = useAnalyticsFacade();
  return (
    <Box paddingY="large">
      <Inline space="small">
        <Text size="standard">
          <TextLink
            href={newPathWithoutLocation}
            weight="weak"
            onClick={() => {
              analyticsFacade.poeaLinksPressed({
                currentPage: 'search results',
                linkText: 'POEA jobs',
                linkAction: 'search breadcrumb',
                linkPosition: 'body',
              });
              dispatch(showPoeaCountryPickerFromNavigation(true));
            }}
            data={{ automation: 'poea-breadcrumb' }}
          >
            {t('POEA jobs')}
          </TextLink>
        </Text>
        <Text>
          <IconChevron direction="right" />
        </Text>
        <Text component="h1" weight="medium" size="standard">
          {capitalizeFirstLetter(filteredKeyword || '')} {t('jobs')}{' '}
          {location && `in ${location}`}
        </Text>
      </Inline>
    </Box>
  );
};

export default POEAHeading;
