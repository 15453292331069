/**
 * Job state & Behavioral tags documentation
 * - https://api.jobapi.net/docs/mordor/tags
 *
 * Job state annotations
 * - EARLY_APPLICANT: 'Be an early applicant'
 * - EXPIRES_SOON: 'Expiring soon'
 *
 * Behavioral annotations
 * - NEW: 'New to you'
 * - VIEWED: 'Viewed'
 * - APP_STARTED: 'Started Applying'
 * - APP_FINISHED: 'Applied'
 * - URGENT: 'Immediate start'
 * - UNSEEN: 'Unseen'
 * - SEEN: 'Seen'
 */

import type { JobTag, JobTagType } from '@seek/chalice-types';

import { mapJobTagsType } from '../mapJobTagsType';

/**
 * Note:
 *  - NEW & UNSEEN are only shown when the job has no Hubble events
 *    associated with it for the current user.
 */
const filterTagsWhenJobIsApplied = (isApplied: boolean) => (item: JobTag) => {
  if (isApplied) {
    return item.type !== 'EARLY_APPLICANT' && item.type !== 'EXPIRES_SOON';
  }
  return true;
};

const filterEarlyApplicantIfExpiresSoonExists =
  (tagTypes: Set<JobTagType>) => (item: JobTag) => {
    const hasEarlyApplicant = tagTypes.has('EARLY_APPLICANT');
    const hasExpiresSoon = tagTypes.has('EXPIRES_SOON');

    if (hasExpiresSoon && hasEarlyApplicant) {
      return item.type !== 'EARLY_APPLICANT';
    }
    return true;
  };

const filterViewedIfApplyStartedExists =
  (tagTypes: Set<JobTagType>) => (item: JobTag) => {
    const hasAppStarted = tagTypes.has('APP_STARTED');

    if (hasAppStarted) {
      return item.type !== 'VIEWED';
    }
    return true;
  };

const filterViewedAndApplyStartedIfApplyFinishedExists =
  (tagTypes: Set<JobTagType>) => (item: JobTag) => {
    const hasAppFinished = tagTypes.has('APP_FINISHED');

    if (hasAppFinished) {
      return item.type !== 'VIEWED' && item.type !== 'APP_STARTED';
    }
    return true;
  };

const filterTagsWhenJobIsFeatured = (isFeatured: boolean) => (item: JobTag) => {
  if (isFeatured) {
    return (
      item.type !== 'NEW' &&
      item.type !== 'UNSEEN' &&
      item.type !== 'VIEWED' &&
      item.type !== 'APP_STARTED' &&
      item.type !== 'APP_FINISHED'
    );
  }
  return true;
};

const filterSeenTag = (item: JobTag) => item.type !== 'SEEN';

const jobTagsTypeSet = (jobTags: JobTag[]): Set<JobTagType> =>
  new Set(mapJobTagsType(jobTags));

export const filterJobTags = ({
  jobTags,
  isFeatured,
  isApplied,
}: {
  jobTags: JobTag[] | undefined;
  isFeatured: boolean;
  isApplied: boolean;
}) => {
  if (!jobTags?.length) return [];

  const tagTypes = jobTagsTypeSet(jobTags);
  return jobTags
    .filter(filterSeenTag)
    .filter(filterTagsWhenJobIsFeatured(isFeatured))
    .filter(filterEarlyApplicantIfExpiresSoonExists(tagTypes))
    .filter(
      filterTagsWhenJobIsApplied(isApplied || tagTypes.has('APP_FINISHED')),
    )
    .filter(filterViewedIfApplyStartedExists(tagTypes))
    .filter(filterViewedAndApplyStartedIfApplyFinishedExists(tagTypes));
};
