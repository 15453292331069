/* eslint no-sequences: "off"*/
// Provided by Socrates Mechanicos
// These keywords should not be followed when their type is blank.
const BLACKLIST = [
  'eastern suburbs',
  'western australia',
  'homes',
  'careers australia group',
  'service nsw',
  'quality safety',
  'food works',
  'big',
  'cover more',
  'allowance',
  'visa',
  'business analysts',
  'home care',
  'the iconic',
  'child',
  'skill hire',
  'overseas',
  'acquisitions',
  'gold coast',
  'smart',
  'all 4 people',
  'staff australia',
  'one key resources',
  'choice one',
  'asia pacific',
  'interface',
  'manukau',
  'get qualified australia',
  'project mechanical',
  'project services',
  'care choice',
  'programmed integrated',
  'adobe',
  'study group',
  'sunshine coast',
  'world vision australia',
  'bank west',
  'study group australia',
  'waikato',
  'next media',
  'careers australia',
  'vic police',
  'talent acquisition',
  'vic roads',
  'london',
  'blue care',
  'think talent',
  'australian personnel solutions',
  'michael page',
  'twitter',
  'blue mountains',
  'point media',
  'tauranga',
  'rms',
  'vision critical',
  'dealer support',
  'smart recruitment',
  'canadian',
  'inspectors',
  'williams',
  'asian pacific group',
  'robert half',
  'employment office',
  'dunedin',
  'ndis',
  'message media',
  'raw materials',
  '2 evolve',
  'phd',
  'see civil',
  'healthy children',
  'peoplebank',
  'northland',
  'optimise media',
  'people2people',
  'professional search group',
  'one off',
  'eba',
  'corporate wellness',
  'direct connect',
  'nbn',
  'office max',
  'recruitshop',
  'frontline health',
  'ultimate positioning',
  'paxus',
  'wanaka',
  'papakura',
  'morgan consulting',
  'rfi',
  'stirling andersen',
  'southland',
  'star track',
  'pivotal australia pty ltd',
  'community housing',
  'reckon',
  'east tamaki',
  'injury treatment',
  'porirua',
  'news corp',
  'health world',
  'homecare',
  'rotorua',
  'deloittes',
  'energy safe victoria',
  'smaart recruitment',
  'conquest personnel',
  'sheet metal fabrication',
  'bentleys',
  'rob law maxrecruitment',
  'sage',
  'ansible',
  'aldis',
  'corestaff',
  'canteen',
  'timaru',
  'me bank',
  'adviza',
  'davidson technology',
  'vicroads',
  'commonwealth bank of australia',
  'takapuna',
  'onehunga',
  'north canterbury',
  'south east water',
  'ramsay health',
  'green light',
  'hawkesbury',
  'roads and maritime services',
  'main freight',
  'engage personnel',
  'key media',
  'gallagher',
  'frontline human resources',
  'western sydney university',
  'channel 9',
  'exercise physiology',
  'resolution media',
  'aurec',
  'netsuite',
  'extrastaff',
  'family office',
  'splunk',
  'taupo',
  'lincoln',
  'financial markets authority',
  'labourforce',
  'multi channel network',
  'commerce commission',
  'talent international',
  'interactive intelligence',
  'mexico',
  'lower hutt',
  'hitech',
  'itsm',
  'active network',
  'elasticsearch',
  'finxl',
  'porterallen',
  'greenlane',
  'anglican care',
  'nata',
  'parnell',
  'collector',
  'crawford',
  'burleigh',
  'veeam',
  'nps',
  'soldier on',
  'animates',
  'nepean',
  'peoplesoft',
  'bartons',
  'hybrid news limited',
  'morgan mckinley',
  'st john of god',
  'blue star',
  'cater care',
  'servicenow',
  'mulesoft',
  'ponsonby',
  'abc',
  'workday',
  'fairfax',
  'viiew',
  'informatica',
  'china sourcing',
  'daniels health',
  'aftercare',
  'ihg',
  'kronos',
  'lush',
  'smartsalary',
  'agricultural appointments',
  'kelloggs',
  'marketo',
  'unitingcare',
  'new lynn',
  'remuera',
  'commvault',
  'mangere',
  'ebs',
  'ford peterson',
  'learning dimensions',
  'red consulting group',
  'employsure',
  'rank media',
  'hayes',
  'loves data',
  'marsh',
  'one harvest',
  'micropay',
  'vero',
  'hornby',
  'sushi sushi',
  'thames',
  'strikeforce',
  'hino',
  'computer power plus',
  'recruitloop',
  'the intelligent investor',
  'masterton',
  'pukekohe',
  'takanini',
  'all perth',
  'digital garden',
  'dinan moore',
  'upper hutt',
  'whakatane',
  'forrest',
  'rolls royce',
  'boutique homes',
  'call active',
  'aurrum',
  'procare',
  'crowne plaza',
  'airport parking',
  'coromandel',
  'morrison',
  'workmates australia',
  'becks wiggins stokes',
  'farmlands',
  'wds',
  'fortinet',
  'grey lynn',
  'kangan',
  'aurion',
  'esk',
  'chisholm institute',
  'bankwest',
  'lexus',
  'mbie',
  'shopify',
  'apts',
  'centacare',
  'culture kings',
  'great southern press',
  'oamaru',
  'silverstripe',
  'cerner',
  'skechers',
  'taking shape',
  'accc',
  'adult entertainment',
  'blackboard',
  'redpath mining',
  'srg',
  'brickworks',
  'cromwell',
  'jeld wen',
  'motorone',
  'tokoroa',
  'waitomo',
  'aia insurance',
  'asahi',
  'orora',
  'cardiac science',
  'catholiccare',
  'dropbox',
  'home direct',
  'langham',
  'squiz',
  'strike force',
  'te awamutu',
  'zendesk',
  'camper trailer',
  'microvellum',
  'oshclub',
  'paramatta',
  'silver chain',
  'summerset',
  'jennings',
  'levin',
  'northrop grumman',
  'riccarton',
  'galaxy personnel',
  'lifeline',
  'marton',
  'pakuranga',
  'price line',
  'rangiora',
  'super amart',
  'whangaparaoa',
  'precruitment',
  'aesop',
  'glaxosmithkline',
  'hawera',
  'macmahon',
  'mastercard',
  'opentext',
  'amsa',
  'bartending',
  'manurewa',
  'ozstaff',
  'paraparaumu',
  'sonic healthplus',
  'tesla',
  'aemo',
  'arnotts',
  'endeavour college',
  'metals x',
  'orewa',
  'red bull',
  'shirley',
  'wilson rice',
  'hobsonville',
  'icebreaker',
  'nzme',
  'parkroyal',
  'siteminder',
  'yooralla',
  'melbourne ivf',
  'nintex',
  'ptv',
  'austal',
  'eit',
  'joy global',
  'labour power',
  'laurent',
  'morrinsville',
  'penske',
  'schindler',
  'smart salary',
  'tricare',
  'youi',
  'digital forensics',
  'katikati',
  'kumeu',
  'microstrategy',
  'officemax',
  'pokeno',
  'sapphire group',
  'valmont',
  'vicinity centres',
  'colgate',
  'kerikeri',
  'lindt',
  'matamata',
  'mondelez',
  'sockburn',
  'discovery holiday parks',
  'grays online',
  'kaplan',
  'kikki.k',
  'makita',
  'mark group',
  'otaki',
  'airservices',
  'amart all sports',
  'browns bay',
  'channel 7',
  'cheil',
  'corelogic',
  'ferrero',
  'hydraulink',
  'sanford',
  'sephora',
  'talentpath',
  'tawa',
  'acciona',
  'discovery parks',
  'genesiscare',
  'helloworld',
  'kiwirail',
  'methven',
  'moffat',
  'motion design',
  'polycom',
  'royal oak',
  'schiavello',
  'william hill',
  'worleyparsons',
  'yellowfin',
  'airmaster',
  'altius group',
  'australian military bank',
  'chiyoda',
  'common wealth bank',
  'emerson',
  'hockingstuart',
  'jaybro',
  'kaiapoi',
  'merlin entertainments',
  'pillow talk',
  'royal sydney golf club',
  'seymour centre',
  'teamviewer',
  'ultra tune',
  'waihi',
  '2evolve',
  'carechoice',
  'dannevirke',
  'eva wilson consulting',
  'fleetpartners',
  'g star',
  'hello fresh',
  'ironfish',
  'local search',
  'localsearch',
  'matakana',
  'rex',
  'scholastic',
  'tassal',
  'thermo fisher',
  'unity4',
  'yancoal',
  'adhb',
  'australiansuper',
  'forcast',
  'hellofresh',
  'hipages group',
  'kelston',
  'kimberly clark',
  'linkforce',
  'melbourne polytechnic',
  'mission bay',
  'mission providence',
  'peter maccallum',
  'sg fleet',
  'snowy hydro',
  'sterile services',
  'theta',
  'vacc',
  'vermeer',
  'waiuku',
  'web drive',
  'audio clinic',
  'bankstown airport',
  'chief mate',
  'coty',
  'frucor',
  'igt',
  'intelligent pathways',
  'ixom',
  'mecca brands',
  'mecca maxima',
  'money3',
  'neca',
  'niagara',
  'novell',
  'ozsale',
  'star track express',
  'te anau',
  'timetarget',
  'yha',
  'banksa',
  'bose',
  'dargaville',
  'electrolux',
  'haileybury',
  'james hardie',
  'kaikohe',
  'kawerau',
  'mundipharma',
  'nlc',
  'quantium',
  'rna',
  'sealord',
  'shaver shop',
  'skyline landscape services',
  'ubet',
  'viva energy',
  'wdhb',
  'widex',
  'wildfire',
  'ampcontrol',
  'citi bank',
  'custom fleet',
  'dicker data',
  'fronde',
  'funlab',
  'half moon bay',
  'max brenner',
  'nbnco',
  'nmit',
  'ozmosis',
  'puma energy',
  'putaruru',
  'sharp corporation',
  'sheike',
  'st heliers',
  'sulzer',
  'ultratune',
  'valmec',
  'wh smith',
  'williams sonoma',
  'bigw',
  'civeo',
  'compumenn',
  'comvita',
  'csk nexus',
  'dorma',
  'dupont',
  'fortescue metals group',
  'gfk',
  'glendowie',
  'ironman',
  'ljhooker',
  'm.j. bale',
  'millers clothing',
  'motorama',
  'movember',
  'nutricia',
  'oceania aviation',
  'open text',
  'otorohanga',
  'paeroa',
  'penguin random house',
  'red dot',
  'rheem',
  'sage institute',
  'south32',
  'te kuiti',
  'teg',
  'tuakau',
  'turangi',
  'waikanae',
  'wirtgen',
  'zeacom',
  'rocket lab',
  'b braun',
  'bjb global',
  'centrecare',
  'delaval',
  'enermech',
  'forester',
  'hardie grant',
  'hartmann',
  'keune australia',
  'langham sydney',
  'ledified',
  'mangawhai',
  'megasealed',
  'miramar',
  'mosaic recruitment',
  'nextdc',
  'ngaruawahia',
  'odecee',
  'peoplescout',
  'peoplesense',
  'precise air',
  'redkite',
  'ritchies iga',
  'sps commerce',
  'tata consultancy services',
  'titirangi',
  'tyrepower',
  'unidata',
  'affco',
  'airbnb',
  'alfa laval',
  'asaleo care',
  'avjennings',
  'blue cross',
  'blueapache',
  'brazilian butterfly',
  'carterton',
  'cold fusion',
  'commsec',
  'coogee chemicals',
  'desa',
  'diplomatic mission',
  'elabor8',
  'gazman',
  'gbst',
  'hankook',
  'jurox',
  'mangere bridge',
  'melbourne victory',
  'messagemedia',
  'moore personnel',
  'myrepublic',
  'ngv',
  'oticon',
  'outbrain',
  'private media',
  'qenos',
  'rauland',
  'rookwood',
  'tr group',
  'union square software',
  'urbanest',
  'whitianga',
  'winz',
  'appian',
  'ardmore',
  'belfast',
  'bioceuticals',
  'carl zeiss',
  'carpentaria contracting',
  'condura',
  'datatrax',
  'dorel',
  'essilor',
  'heatcraft',
  'heinemann',
  'hrx',
  'inform group',
  'kaiwaka',
  'kleenheat',
  'legrand',
  'monash ivf',
  'mount albert',
  'neopost',
  'nida',
  'oil search',
  'opotiki',
  'pharmacare',
  'riverhead',
  'seqwater',
  'sirva',
  'sunwater',
  'te aroha',
  'unico',
  'valvoline',
  'waimate',
  'zimmer',
  'amtek',
  'axalta',
  'canley vale',
  'castleford media',
  'cfmeu',
  'corality financial group',
  'cyient',
  'david moss',
  'eletrical',
  'eltham college',
  'enlighten designs',
  'falck',
  'farragher',
  'ferragamo',
  'fujifilm',
  'fusion5',
  'globalx',
  'graysonline',
  'gulf harbour',
  'hanrob',
  'helensville',
  'indue',
  'jbwere',
  'jtb',
  'kohimarama',
  'langdon ingredients',
  'locus',
  'lucas total contract solutions',
  'mad mex',
  'maxam',
  'mrael',
  'mybudget',
  'neptune marine services',
  'netball australia',
  'pahiatua',
  'pcsg',
  'quadrant energy',
  'questas',
  'r.m. williams',
  'ruah',
  'sahmri',
  'snells beach',
  'tab corp',
  'uni sa',
  'victorias secret',
  'waimauku',
  'waipu',
  'wesbeam',
  'western bulldogs',
  'wex',
  'wolters kluwer',
  'woodards',
  'yakult',
  'zenithoptimedia',
  'afex',
  'afi branding solutions',
  'airtasker',
  'aldo',
  'allergan',
  'amnet',
  'bank sa',
  'beach energy',
  'binary options',
  'bluecare',
  'bourke street bakery',
  'bravehearts',
  'bridgeclimb',
  'burns for blinds',
  'c sharp',
  'candle new zealand',
  'cassons',
  'cengage',
  'convatec',
  'damos',
  'deliveroo',
  'forsyth barr',
  'foxtell',
  'furla',
  'hasbro',
  'holman fenwick willan',
  'invetech',
  'isagenix',
  'jav it',
  'kiwi rail',
  'kordamentha',
  'landpartners',
  'leadbolt',
  'leeston',
  'ltt',
  'mattel',
  'menzies consulting',
  'mighty ape',
  'my food bag',
  'mystate',
  'nearmap',
  'ngatea',
  'oneflare',
  'orcon',
  'parakai',
  'pembroke',
  'quintiq',
  'raywhite',
  'riotinto',
  'sarens',
  'sigma aldrich',
  'sika',
  'sika australia',
  'silver chef',
  'solgen',
  'speedpanel',
  'sunbeam',
  'surfstitch',
  'takaka',
  'te papa',
  'technetics',
  'thomas warburton',
  'ubiquity',
  'virtunet',
  'vitalcare',
  'waihi beach',
  'weta workshop',
  'whangamata',
  'ych',
  '99bikes',
  'adlogic',
  'allianze',
  'ampco',
  'aqwa',
  'aussiecommerce',
  'austramedex',
  'austwide consumer products',
  'av jennings',
  'bevchain',
  'bhp biliton',
  'bhpbilliton',
  'buckby contracting',
  'buru energy',
  'careflight',
  'chandler macloed',
  'chandler macloud',
  'chandler mcleod',
  'chandler mcloud',
  'chanel 9',
  'chemist ware house',
  'chemist wharehouse',
  'citybeach',
  'commonwelath bank',
  'contiigo',
  'coolgen',
  'covermore',
  'e2o',
  'engage viidacom',
  'experieco',
  'exxon mobil',
  'flexirobes',
  'floveyor',
  'ganellen',
  'hannover fairs australia',
  'healthconnex',
  'hocking stuart',
  'hologic',
  'huapai',
  'imdex',
  'jeans west',
  'jeldwen',
  'jobsite recruitment',
  'kiewit',
  'kimberley clark',
  'kiwi bank',
  'laing orourke',
  'leeming medical centre',
  'logmein inc',
  'mako networks',
  'marne',
  'mebank',
  'mecca cosmetics',
  'medland metropolis',
  'monodelphous',
  'morpho',
  'netcracker',
  'norgine',
  'novo nordisk',
  'nrs media',
  'onesubsea',
  'optician',
  'optimation',
  'orrcon',
  'philip morris',
  'planet tel',
  'priceline sydney',
  'programmed intergrated',
  'qt bondi',
  'racwa',
  'randstadt',
  'redbubble',
  'regis resources',
  'scitech',
  'secure corp',
  'senex energy',
  'servicestream',
  'shell prelude',
  'silverchain',
  'skillsoft',
  'southbridge',
  'spud shed',
  'sterling publishing',
  'studygroup',
  'sullair',
  'swire oilfield services',
  'tasman oil tools',
  'tegal',
  'telestra',
  'toowoomba holden',
  'transmin',
  'ubank',
  'vetstar',
  'vicsuper',
  'weber shandwick',
  'worley parsons',
  '',
];

const BLANKS_BLACKLIST = BLACKLIST.reduce(
  (acc, val) => ((acc[val] = true), acc),
  {} as Record<string, boolean>,
);

export default BLANKS_BLACKLIST;
