
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6IlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IkthbWkgc2VkYW5nIG1lbXByb3Nlc255YS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEsIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IkthbWkgc2VkYW5nIG1lbXByb3Nlc255YS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEsIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IuC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4q%2BC4p%2BC5iOC4suC4h%2BC4geC4suC4o%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4giIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiLguYDguKPguLLguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguKrguJTguIfguKvguJnguYnguLLguJnguLXguYnguYTguJTguYkg4LiC4LiT4Liw4LiZ4Li14LmJ4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiH4LiU4Liz4LmA4LiZ4Li04LiZ4LiB4Liy4Lij4LmB4LiB4LmJ4LmE4LiCIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiLguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IuC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4q%2BC4p%2BC5iOC4suC4h%2BC4geC4suC4o%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4giIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiLguYDguKPguLLguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguKrguJTguIfguKvguJnguYnguLLguJnguLXguYnguYTguJTguYkg4LiC4LiT4Liw4LiZ4Li14LmJ4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiH4LiU4Liz4LmA4LiZ4Li04LiZ4LiB4Liy4Lij4LmB4LiB4LmJ4LmE4LiCIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiLguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IkthbWkgc2VkYW5nIG1lbXByb3Nlc255YS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEsIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IkthbWkgc2VkYW5nIG1lbXByb3Nlc255YS4iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiU2FhdCBpbmkga2FtaSB0aWRhayBkYXBhdCBtZW11YXRueWEsIGRhbiBzZWRhbmcgYmVydXNhaGEgbWVtcGVyYmFpa2lueWEuIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiJDb2JhIG11YXQgdWxhbmcgaGFsYW1hbiBhdGF1IHBlcmlrc2EgbGFnaSBuYW50aS4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IuC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4q%2BC4p%2BC5iOC4suC4h%2BC4geC4suC4o%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4giIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiLguYDguKPguLLguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguKrguJTguIfguKvguJnguYnguLLguJnguLXguYnguYTguJTguYkg4LiC4LiT4Liw4LiZ4Li14LmJ4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiH4LiU4Liz4LmA4LiZ4Li04LiZ4LiB4Liy4Lij4LmB4LiB4LmJ4LmE4LiCIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiLguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IuC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4q%2BC4p%2BC5iOC4suC4h%2BC4geC4suC4o%2BC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5geC4geC5ieC5hOC4giIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiLguYDguKPguLLguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguKrguJTguIfguKvguJnguYnguLLguJnguLXguYnguYTguJTguYkg4LiC4LiT4Liw4LiZ4Li14LmJ4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiH4LiU4Liz4LmA4LiZ4Li04LiZ4LiB4Liy4Lij4LmB4LiB4LmJ4LmE4LiCIiwiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4iOiLguKPguLXguYDguJ%2FguKPguIrguKvguJnguYnguLLguYDguJ7guIjguKvguKPguLfguK3guKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIfguYPguJnguKDguLLguKLguKvguKXguLHguIcifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IlvFtOG6veG6veG6vSfFmeG6veG6veG6vSDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgw6zDrMOs4bmvLl0iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJJ%2BG5ryDGmsO2w7bDtsSDxIPEg8aMIOG5r%2BG4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbUgw5%2FHmseax5rhua8gxbXhur3hur3hur0nxZnhur3hur3hur0gxozDtsO2w7bDrMOsw6zguIHguLXguYnEoyDDtsO2w7bHmseax5rFmSDDn%2BG6veG6veG6vcWh4bmvIOG5r8O2w7bDtiDGksOsw6zDrOqtlSDDrMOsw6zhua8uXSIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW%2BG5rsWZw73DvcO9IMWZ4bq94bq94bq9xpLFmeG6veG6veG6vcWh4bipw6zDrMOs4LiB4Li14LmJxKMg4bmv4bip4bq94bq94bq9IMalxIPEg8SDxKPhur3hur3hur0gw7bDtsO2xZkgw6fhuKnhur3hur3hur3Dp8S3IMOfxIPEg8SDw6fEtyDGmsSDxIPEg%2BG5r%2BG6veG6veG6vcWZLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IlvFtOG6veG6veG6vSfFmeG6veG6veG6vSDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgw6zDrMOs4bmvLl0iLCJXZSBjYW4ndCBsb2FkIHRoaXMgcmlnaHQgbm93IGJ1dCB3ZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggaXQuIjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJJ%2BG5ryDGmsO2w7bDtsSDxIPEg8aMIOG5r%2BG4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua8g4LiB4Li14LmJw7bDtsO2xbUgw5%2FHmseax5rhua8gxbXhur3hur3hur0nxZnhur3hur3hur0gxozDtsO2w7bDrMOsw6zguIHguLXguYnEoyDDtsO2w7bHmseax5rFmSDDn%2BG6veG6veG6vcWh4bmvIOG5r8O2w7bDtiDGksOsw6zDrOqtlSDDrMOsw6zhua8uXSIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiW%2BG5rsWZw73DvcO9IMWZ4bq94bq94bq9xpLFmeG6veG6veG6vcWh4bipw6zDrMOs4LiB4Li14LmJxKMg4bmv4bip4bq94bq94bq9IMalxIPEg8SDxKPhur3hur3hur0gw7bDtsO2xZkgw6fhuKnhur3hur3hur3Dp8S3IMOfxIPEg8SDw6fEtyDGmsSDxIPEg%2BG5r%2BG6veG6veG6vcWZLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    