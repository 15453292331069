
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiU29ycnksIHRoZXJlIGFyZSBubyBqb2JzIGF0IHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJUcnkgYWRqdXN0aW5nIHRoZSBmaWx0ZXJzIG9yIGNoZWNrIGZvciBzcGVsbGluZyBlcnJvcnMuIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiTWFhZiwgdGlkYWsgYWRhIGxvd29uZ2FuIGtlcmphIGRpIHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJUaWRhayBhZGEgaGFzaWwgcGVuY2FyaWFuIHlhbmcgc2VzdWFpIiwiV2UgY291bGRuJ3QgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIHNlc3VhaSIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJDb2JhIHViYWggZmlsdGVyIGF0YXUgcGVyaWtzYSBwZW51bGlzYW5tdSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiTWFhZiwgdGlkYWsgYWRhIGxvd29uZ2FuIGtlcmphIGRpIHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJUaWRhayBhZGEgaGFzaWwgcGVuY2FyaWFuIHlhbmcgc2VzdWFpIiwiV2UgY291bGRuJ3QgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIHNlc3VhaSIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJDb2JhIHViYWggZmlsdGVyIGF0YXUgcGVyaWtzYSBwZW51bGlzYW5tdSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoi4LiC4Lit4Lit4Lig4Lix4LiiIOC5hOC4oeC5iOC4oeC4teC4h%2BC4suC4meC4l%2BC4teC5iHtrZXl3b3Jkc30iLCJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4guC4reC4h%2BC4hOC4uOC4kyIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiLguKXguK3guIfguJvguKPguLHguJrguJXguLHguKfguIHguKPguK3guIfguKvguKPguLfguK3guJXguKPguKfguIjguKrguK3guJrguKfguYjguLLguKHguLXguIHguLLguKPguKrguLDguIHguJTguJzguLTguJTguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoi4LiC4Lit4Lit4Lig4Lix4LiiIOC5hOC4oeC5iOC4oeC4teC4h%2BC4suC4meC4l%2BC4teC5iHtrZXl3b3Jkc30iLCJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4guC4reC4h%2BC4hOC4uOC4kyIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiLguKXguK3guIfguJvguKPguLHguJrguJXguLHguKfguIHguKPguK3guIfguKvguKPguLfguK3guJXguKPguKfguIjguKrguK3guJrguKfguYjguLLguKHguLXguIHguLLguKPguKrguLDguIHguJTguJzguLTguJTguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiTWFhZiwgdGlkYWsgYWRhIGxvd29uZ2FuIGtlcmphIGRpIHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJUaWRhayBhZGEgaGFzaWwgcGVuY2FyaWFuIHlhbmcgc2VzdWFpIiwiV2UgY291bGRuJ3QgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIHNlc3VhaSIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJDb2JhIHViYWggZmlsdGVyIGF0YXUgcGVyaWtzYSBwZW51bGlzYW5tdSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiTWFhZiwgdGlkYWsgYWRhIGxvd29uZ2FuIGtlcmphIGRpIHtrZXl3b3Jkc30uIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJUaWRhayBhZGEgaGFzaWwgcGVuY2FyaWFuIHlhbmcgc2VzdWFpIiwiV2UgY291bGRuJ3QgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIHNlc3VhaSIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJDb2JhIHViYWggZmlsdGVyIGF0YXUgcGVyaWtzYSBwZW51bGlzYW5tdSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoi4LiC4Lit4Lit4Lig4Lix4LiiIOC5hOC4oeC5iOC4oeC4teC4h%2BC4suC4meC4l%2BC4teC5iHtrZXl3b3Jkc30iLCJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4guC4reC4h%2BC4hOC4uOC4kyIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiLguKXguK3guIfguJvguKPguLHguJrguJXguLHguKfguIHguKPguK3guIfguKvguKPguLfguK3guJXguKPguKfguIjguKrguK3guJrguKfguYjguLLguKHguLXguIHguLLguKPguKrguLDguIHguJTguJzguLTguJTguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoi4LiC4Lit4Lit4Lig4Lix4LiiIOC5hOC4oeC5iOC4oeC4teC4h%2BC4suC4meC4l%2BC4teC5iHtrZXl3b3Jkc30iLCJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbid0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IuC5gOC4o%2BC4suC5hOC4oeC5iOC4nuC4muC4nOC4peC4peC4seC4nuC4mOC5jOC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4muC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4guC4reC4h%2BC4hOC4uOC4kyIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiLguKXguK3guIfguJvguKPguLHguJrguJXguLHguKfguIHguKPguK3guIfguKvguKPguLfguK3guJXguKPguKfguIjguKrguK3guJrguKfguYjguLLguKHguLXguIHguLLguKPguKrguLDguIHguJTguJzguLTguJTguKvguKPguLfguK3guYTguKHguYgifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiW8Wgw7bDtsO2xZnFmcO9w73DvSwg4bmv4bip4bq94bq94bq9xZnhur3hur3hur0gxIPEg8SDxZnhur3hur3hur0g4LiB4Li14LmJw7bDtsO2IMS1w7bDtsO2w5%2FFoSDEg8SDxIPhua8ge2tleXdvcmRzfS5dIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJbw5HDtsO2w7YgbcyCxIPEg8SD4bmvw6fhuKnDrMOsw6zguIHguLXguYnEoyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxZnhur3hur3hur3Foceax5rHmsaa4bmvxaFdIiwiV2UgY291bGRuJ3QgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiW8W04bq94bq94bq9IMOnw7bDtsO2x5rHmseaxprGjOC4geC4teC5iSfhua8gxpLDrMOsw6zguIHguLXguYnGjCDEg8SDxIPguIHguLXguYnDvcO9w73hua%2FhuKnDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnEg8SDxIPhua8gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3GjCDDvcO9w73DtsO2w7bHmseax5rFmSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJb4bmuxZnDvcO9w70gxIPEg8SDxozEtceax5rHmsWh4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bip4bq94bq94bq9IMaSw6zDrMOsxprhua%2Fhur3hur3hur3FmcWhIMO2w7bDtsWZIMOn4bip4bq94bq94bq9w6fEtyDGksO2w7bDtsWZIMWhxqXhur3hur3hur3Gmsaaw6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq9xZnFmcO2w7bDtsWZxaEuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgYXJlIG5vIGpvYnMgYXQge2NvbXBhbnlTdWdnZXN0aW9uLnNlYXJjaC5rZXl3b3Jkc30uIjoiW8Wgw7bDtsO2xZnFmcO9w73DvSwg4bmv4bip4bq94bq94bq9xZnhur3hur3hur0gxIPEg8SDxZnhur3hur3hur0g4LiB4Li14LmJw7bDtsO2IMS1w7bDtsO2w5%2FFoSDEg8SDxIPhua8ge2tleXdvcmRzfS5dIiwiTm8gbWF0Y2hpbmcgc2VhcmNoIHJlc3VsdHMiOiJbw5HDtsO2w7YgbcyCxIPEg8SD4bmvw6fhuKnDrMOsw6zguIHguLXguYnEoyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxZnhur3hur3hur3Foceax5rHmsaa4bmvxaFdIiwiV2UgY291bGRuJ3QgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiW8W04bq94bq94bq9IMOnw7bDtsO2x5rHmseaxprGjOC4geC4teC5iSfhua8gxpLDrMOsw6zguIHguLXguYnGjCDEg8SDxIPguIHguLXguYnDvcO9w73hua%2FhuKnDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnEg8SDxIPhua8gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3GjCDDvcO9w73DtsO2w7bHmseax5rFmSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIlRyeSBhZGp1c3RpbmcgdGhlIGZpbHRlcnMgb3IgY2hlY2sgZm9yIHNwZWxsaW5nIGVycm9ycy4iOiJb4bmuxZnDvcO9w70gxIPEg8SDxozEtceax5rHmsWh4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmv4bip4bq94bq94bq9IMaSw6zDrMOsxprhua%2Fhur3hur3hur3FmcWhIMO2w7bDtsWZIMOn4bip4bq94bq94bq9w6fEtyDGksO2w7bDtsWZIMWhxqXhur3hur3hur3Gmsaaw6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq9xZnFmcO2w7bDtsWZxaEuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    