import { useTranslations } from '@vocab/react';
import { Box, Text, Stack } from 'braid-design-system';

import translations from './.vocab';

import * as styles from './UpToDateLastPage.css';

export const UpToDateLastPage = () => {
  const { t } = useTranslations(translations);

  return (
    <Box className={styles.upToDateLastPage}>
      <Box className={styles.upToDateItem}>
        <Stack space="small">
          <Text
            weight="strong"
            size="large"
            align={{
              mobile: 'center',
              tablet: 'left',
            }}
          >
            {t("You're up to date!")}
          </Text>
          <Text
            size="small"
            align={{
              mobile: 'center',
              tablet: 'left',
            }}
          >
            {t('No new jobs for this search')}
          </Text>
        </Stack>
      </Box>
      <Box className={styles.upToDateItem}>
        <svg
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M51.2213 26.5681C51.2213 28.7868 49.4227 30.5855 47.2039 30.5855C44.9852 30.5855 43.1865 28.7868 43.1865 26.5681C43.1865 24.3493 44.9852 22.5507 47.2039 22.5507C49.4227 22.5507 51.2213 24.3493 51.2213 26.5681ZM47.2039 25.212C46.455 25.212 45.8479 25.8191 45.8479 26.5681C45.8479 27.317 46.455 27.9241 47.2039 27.9241C47.9528 27.9241 48.56 27.317 48.56 26.5681C48.56 25.8191 47.9528 25.212 47.2039 25.212Z"
            fill="#FDC221"
          />
          <path
            d="M32.234 20.5154C32.234 22.7488 30.4234 24.5594 28.19 24.5594C25.9566 24.5594 24.146 22.7488 24.146 20.5154C24.146 18.2819 25.9566 16.4714 28.19 16.4714C30.4234 16.4714 32.234 18.2819 32.234 20.5154ZM28.19 19.1504C27.4361 19.1504 26.825 19.7615 26.825 20.5154C26.825 21.2692 27.4361 21.8804 28.19 21.8804C28.9439 21.8804 29.555 21.2692 29.555 20.5154C29.555 19.7615 28.9439 19.1504 28.19 19.1504Z"
            fill="#56D3DF"
          />
          <path
            d="M39.1692 28.5768C35.841 28.5768 33.1431 31.2748 33.1431 34.6029H39.1692V28.5768Z"
            fill="#6E56E6"
          />
          <path
            d="M65.2822 14.5159C68.6104 14.5159 71.3083 17.2138 71.3083 20.542H65.2822V14.5159Z"
            fill="#FDC221"
          />
          <path
            d="M57.8358 14.5159L53.5747 18.777L54.9951 20.1973L59.2562 15.9362L57.8358 14.5159Z"
            fill="#56D3DF"
          />
          <path
            d="M66.2862 24.5834H64.2775C64.2775 25.8938 63.4323 27.008 62.2597 27.4173V26.5874C62.2597 24.9286 60.9104 23.579 59.2513 23.579C57.5928 23.579 56.2432 24.9286 56.2432 26.5874C56.2432 28.2462 57.5928 29.5956 59.2513 29.5956H60.0727C59.6569 30.7559 58.549 31.5898 57.2475 31.5898V33.5985C59.6951 33.5985 61.7352 31.8338 62.1707 29.5107C64.5072 29.0863 66.2862 27.0406 66.2862 24.5834ZM58.2519 26.5874C58.2519 26.0362 58.7001 25.5878 59.2513 25.5878C59.8025 25.5878 60.251 26.0362 60.251 26.5874V27.5869H59.2513C58.7001 27.5869 58.2519 27.1387 58.2519 26.5874Z"
            fill="#6E56E6"
          />
          <path
            d="M42.1821 12.503C42.1821 10.8442 40.8325 9.49463 39.1739 9.49463C37.5149 9.49463 36.1655 10.8442 36.1655 12.503V13.3243C35.0052 12.9083 34.1713 11.8006 34.1713 10.499H32.1626C32.1626 12.9465 33.9273 14.9866 36.2504 15.4221C36.6748 17.7588 38.7205 19.5376 41.1777 19.5376V17.5289C39.8674 17.5289 38.7532 16.6839 38.3439 15.5112H39.1739C40.8325 15.5112 42.1821 14.1618 42.1821 12.503ZM38.1742 12.503C38.1742 11.9518 38.6227 11.5033 39.1739 11.5033C39.7251 11.5033 40.1734 11.9518 40.1734 12.503C40.1734 13.0542 39.7251 13.5025 39.1739 13.5025H38.1742V12.503Z"
            fill="#EE399E"
          />
          <path
            d="M51.2211 38.6203C52.3305 38.6203 53.2298 37.721 53.2298 36.6116C53.2298 35.5022 52.3305 34.6029 51.2211 34.6029C50.1117 34.6029 49.2124 35.5022 49.2124 36.6116C49.2124 37.721 50.1117 38.6203 51.2211 38.6203Z"
            fill="#F470BD"
          />
          <path
            d="M51.2211 12.5072C52.3305 12.5072 53.2298 11.6079 53.2298 10.4985C53.2298 9.3891 52.3305 8.48978 51.2211 8.48978C50.1117 8.48978 49.2124 9.3891 49.2124 10.4985C49.2124 11.6079 50.1117 12.5072 51.2211 12.5072Z"
            fill="#FDC221"
          />
          <path
            d="M44.6069 38.9649L43.1865 40.3853L47.4476 44.6464L48.868 43.226L44.6069 38.9649Z"
            fill="#56D3DF"
          />
          <path
            d="M43.7237 58.1671C42.3257 59.1768 39.9331 64.0467 39.9331 64.0467C39.9331 64.0467 39.8857 64.1192 39.81 64.2333C39.7783 60.7549 40.0838 50.7996 40.1373 47.2466C40.1643 45.46 40.2811 42.4183 37.3542 42.2966C35.1353 42.2048 34.5627 45.0193 34.5627 45.0193C34.5627 45.0193 35.5016 39.9775 32.1691 39.7909C29.1175 39.62 28.7609 43.1379 28.6266 44.5675C28.5916 43.7789 28.1784 42.259 26.1567 42.2377C25.1453 42.2266 24.2852 43.1024 23.8084 44.3596C23.1232 46.167 22.9551 48.7148 22.9233 50.3365C22.7857 49.1494 22.2719 47.7289 20.5765 47.7923C18.0046 47.8881 17.8628 51.9264 17.7638 54.0938C17.6419 56.7557 17.6268 59.1798 17.5382 61.8448C17.4319 65.0467 17.2926 67.3775 17.5167 69.6099L12.5404 74.9529C12.5401 74.9529 12.5399 74.9535 12.5397 74.9535L12.5104 74.9849L12.5121 74.9869C11.1313 76.5519 12.6366 80.4416 15.9018 83.7146C19.1511 86.9713 22.9241 88.3958 24.3945 86.9404L24.3975 86.9434L29.4162 81.5547C29.7517 81.5856 30.0905 81.6115 30.4332 81.6328C36.4185 82.0015 41.6803 78.481 43.6439 72.5218C44.9298 69.1266 46.4519 65.0173 47.6545 61.259C48.4932 58.6382 45.6664 56.7639 43.7237 58.1671Z"
            fill="#EBAB7D"
          />
          <path
            d="M28.2334 56.9655C28.2253 56.9655 28.218 56.9655 28.2107 56.9651C27.9301 56.953 27.7119 56.7153 27.7236 56.4345L28.1997 45.2577C28.2121 44.9757 28.4707 44.7472 28.7307 44.7703C29.0112 44.7824 29.2295 45.02 29.2177 45.3009L28.7417 56.4777C28.7299 56.7516 28.5043 56.9655 28.2334 56.9655Z"
            fill="#C17742"
          />
          <path
            d="M34.086 56.9417C34.0802 56.9417 34.0743 56.9417 34.0677 56.9413C33.7865 56.931 33.5668 56.6952 33.577 56.4139L33.9718 45.4368C33.9813 45.1617 34.2076 44.9457 34.4808 44.9457C34.4867 44.9457 34.4925 44.9457 34.4991 44.946C34.7804 44.9563 35.0001 45.1921 34.9898 45.4734L34.5951 56.4506C34.5855 56.7256 34.3592 56.9417 34.086 56.9417Z"
            fill="#C17742"
          />
          <path
            d="M22.7426 59.9006H22.7316C22.4503 59.8944 22.2269 59.6615 22.2335 59.3803L22.4144 51.0687C22.4203 50.7875 22.6276 50.5685 22.9345 50.5703C23.2157 50.5765 23.4391 50.8095 23.4325 51.0907L23.2516 59.4022C23.2457 59.6798 23.0187 59.9006 22.7426 59.9006Z"
            fill="#C17742"
          />
          <path
            d="M79.953 57.4375C78.5669 58.4042 77.5475 59.79 76.6619 62.3668C76.5288 62.754 76.1241 63.4287 76.1173 62.3809C76.1087 61.0391 75.6801 59.172 75.5323 57.7352C75.269 55.1761 74.6262 46.9035 74.1989 43.9484C73.6088 39.8674 68.914 40.2077 68.4703 43.4926C68.2663 39.3738 62.8411 39.0621 62.4503 43.3499C61.5339 40.1995 57.4078 41.0615 56.9921 44.4381C56.7404 46.4824 56.9722 50.2251 56.9722 50.2251C55.8366 47.7113 52.3118 48.6744 52.2396 51.624C52.1105 56.8925 52.2429 59.6861 52.2429 59.6861C52.2429 59.6861 52.6216 67.649 53.3591 71.3945C54.1652 75.4871 55.467 76.8239 59.0007 78.1847C60.3842 78.7175 61.334 78.7547 62.5365 78.755L70.4595 86.9677L70.4625 86.9649C71.9202 88.3577 75.6608 86.9945 78.8822 83.8772C82.1193 80.7446 83.6116 77.0218 82.2427 75.5239L82.2445 75.5222L82.2154 75.492C82.2152 75.4919 82.2151 75.4916 82.2147 75.4913L79.3705 72.5431C80.5047 69.0425 82.5193 64.4117 83.8499 60.3972C84.6813 57.8886 81.8789 56.0944 79.953 57.4375Z"
            fill="#A25F45"
          />
          <path
            d="M74.5331 65.6248C71.1273 65.4137 67.7412 67.3289 66.1465 70.3415C65.849 70.9037 66.7024 71.4042 67.0006 70.8407C68.4437 68.1142 71.4536 66.423 74.5331 66.6139C75.1688 66.6533 75.1665 65.6641 74.5331 65.6248Z"
            fill="#8A3E28"
          />
          <path
            d="M70.9264 57.5033C71.5623 57.5382 71.5604 56.549 70.9264 56.5142C70.4064 56.4856 69.8864 56.4758 69.3666 56.4699C69.1756 52.4524 69.1704 48.428 69.0309 44.4086C69.0089 43.7739 68.0196 43.7709 68.0418 44.4086C68.1813 48.43 68.1864 52.4566 68.3776 56.4761C66.7529 56.5023 65.133 56.6316 63.5299 56.8766C63.2732 52.7356 63.2285 48.5839 63.0715 44.4385C63.0474 43.804 62.0583 43.8009 62.0824 44.4385C62.2403 48.608 62.2837 52.7839 62.5444 56.949C62.5468 56.9854 62.5618 57.0125 62.57 57.0448C61.0138 57.326 59.4745 57.7012 57.9655 58.1864C57.8389 55.8776 57.6165 53.5756 57.5048 51.2656C57.4742 50.632 56.4849 50.6283 56.5157 51.2656C56.6327 53.6879 56.8744 56.1013 56.9963 58.5233C56.6791 58.6354 56.3596 58.74 56.0452 58.8612C55.4575 59.0879 55.7129 60.0446 56.3082 59.815C60.9701 58.0172 65.9372 57.2292 70.9264 57.5033Z"
            fill="#8A3E28"
          />
        </svg>
      </Box>
    </Box>
  );
};
