import type { SearchResultJobV5 } from '@seek/chalice-types';
import { Box, Loader, Stack } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { AccessabilityLinks } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import useAuthenticated from 'src/hooks/useAuthenticated';
import { selectTags } from 'src/store/location/selectors';
import { useSelector } from 'src/store/react';
import type { SearchViewModelCompanySuggestion } from 'src/store/results/types';
import {
  selectFeatureFlag,
  selectIsResultsLoading,
  selectLocationPageNumber,
  selectPaginationTotalPages,
} from 'src/store/selectors';

import { useBehavioralCuesFilters } from '../BehaviouralCuesFiltersContext/BehaviouralCuesFiltersContext';
import NewToYouSignIn from '../NewToYouSignIn/NewToYouSignIn';
import SearchResultList from '../SearchResultList/SearchResultList';
import { UpToDateLastPage } from '../UpToDateLastPage/UpToDateLastPage';
import UpToDateZeroCount from '../UpToDateZeroCount/UpToDateZeroCount';
import ZeroResults from '../ZeroResults/ZeroResults';

import JobDetail from './JobDetail/JobDetail';
import SplitViewLayout from './SplitViewLayout/SplitViewLayout';
import useSplitViewOpen1stJobAdEffect from './useSplitViewOpen1stJobAdEffect/useSplitViewOpen1stJobAdEffect';

import * as styles from './SplitView.css';

interface SplitViewProps {
  jobs: SearchResultJobV5[];
  resultSummary: ReactNode;
  isCompanySearch: ComponentProps<typeof SearchResultList>['isCompanySearch'];
  isZeroResults: boolean;

  companySuggestion?: SearchViewModelCompanySuggestion;
}

const SplitView = ({
  jobs,
  resultSummary,
  isCompanySearch,
  companySuggestion,
  isZeroResults = false,
}: SplitViewProps) => {
  const {
    selectedJob,
    setSelectedJob,
    currentJobDetails,
    fetchJobDetails,
    isLoading,
  } = useSplitViewContext();

  const isSearchLoading = useSelector(selectIsResultsLoading);

  useSplitViewOpen1stJobAdEffect(jobs);

  const totalPages = useSelector(selectPaginationTotalPages);
  const currentPageNumber = useSelector(selectLocationPageNumber);
  const isNewTag = useSelector(selectTags);

  const SrpLoader = () => (
    <Box
      paddingY="xlarge"
      style={{ height: 400 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-automation="searchResultsLoader"
    >
      <Loader size="large" />
    </Box>
  );

  const { state: bCuesFiltersState } = useBehavioralCuesFilters();
  const { showNewJobsSignIn } = bCuesFiltersState;

  const authenticateState = useAuthenticated();

  const showUpToDateZeroCount =
    authenticateState === 'authenticated' &&
    isZeroResults &&
    isNewTag === 'new';

  const hideResults =
    showNewJobsSignIn ||
    showUpToDateZeroCount ||
    isSearchLoading ||
    isZeroResults;
  const shouldShowSaveSearchAbovePagination =
    authenticateState === 'authenticated' && !hideResults;
  const shouldShowSaveSearchUnderPagination =
    authenticateState === 'unauthenticated' && !hideResults;

  const showMRECBanner = !hideResults;

  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  return (
    <Box className={styles.LoadingWrap}>
      {!isSearchLoading && (showNewJobsSignIn || showUpToDateZeroCount) && (
        <Box className={styles.LoadingWrapItem} zIndex={1} marginTop="xxxlarge">
          <Box width="full" className={styles.content}>
            {showNewJobsSignIn ? <NewToYouSignIn /> : <UpToDateZeroCount />}
          </Box>
        </Box>
      )}

      {((!isBehaviouralCuesFiltersEnabled &&
        !isSearchLoading &&
        isZeroResults) ||
        (!showUpToDateZeroCount &&
          !isSearchLoading &&
          isZeroResults &&
          isNewTag !== 'new')) && (
        <Box className={styles.LoadingWrapItem} zIndex={1} marginTop="xxxlarge">
          <Box width="full" className={styles.content}>
            <ZeroResults companySuggestion={companySuggestion} />
          </Box>
        </Box>
      )}

      {isSearchLoading ? (
        <Box className={styles.LoadingWrapItem} zIndex={1} pointerEvents="none">
          <Box width="full" className={styles.content}>
            <SrpLoader />
          </Box>
        </Box>
      ) : null}

      {isZeroResults && !isBehaviouralCuesFiltersEnabled ? null : (
        <Box className={styles.LoadingWrapItem}>
          <SplitViewLayout
            showMRECBanner={showMRECBanner}
            shouldShowSaveSearchAbovePagination={
              shouldShowSaveSearchAbovePagination
            }
            shouldShowSaveSearchUnderPagination={
              shouldShowSaveSearchUnderPagination
            }
            ListView={
              <>
                {resultSummary}
                {hideResults ? null : (
                  <>
                    <Stack space="medium">
                      {jobs.length > 0 && (
                        <SearchResultList
                          jobs={jobs}
                          isCompanySearch={isCompanySearch}
                          setCurrentJob={setSelectedJob}
                          selectedJobId={selectedJob?.id}
                        />
                      )}
                      {isBehaviouralCuesFiltersEnabled &&
                        currentPageNumber === totalPages &&
                        isNewTag === 'new' && <UpToDateLastPage />}
                    </Stack>
                  </>
                )}
              </>
            }
            DetailView={
              hideResults ? null : (
                <>
                  <AccessabilityLinks placement="job-details" position="top" />
                  <JobDetail
                    currentJobDetails={selectedJob ? currentJobDetails : null}
                    fetchJobDetails={fetchJobDetails}
                    isLoading={isLoading}
                  />
                  {selectedJob ? (
                    <AccessabilityLinks
                      placement="job-details"
                      position="bottom"
                    />
                  ) : null}
                </>
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default SplitView;
