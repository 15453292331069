
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGNvbnRhaW5pbmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiU2hvdyB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtqb2J9IG9uZSB7am9ifSBvdGhlciB7am9ic319IGF0IHtjb21wYW55TmFtZX0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IjAgam9icyBhdCB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiVGFtcGlsa2FuIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FuIGtlcmphfSBvbmUge2xvd29uZ2FuIGtlcmphfSBvdGhlciB7bG93b25nYW4ga2VyamF9fSB5YW5nIG1lbmdhbmR1bmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiVGFtcGlsa2FuICB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtsb3dvbmdhbiBrZXJqYX0gb25lIHtsb3dvbmdhbiBrZXJqYX0gb3RoZXIge2xvd29uZ2FuIGtlcmphfX0gZGkge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCBsb3dvbmdhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiVGFtcGlsa2FuIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FuIGtlcmphfSBvbmUge2xvd29uZ2FuIGtlcmphfSBvdGhlciB7bG93b25nYW4ga2VyamF9fSB5YW5nIG1lbmdhbmR1bmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiVGFtcGlsa2FuICB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtsb3dvbmdhbiBrZXJqYX0gb25lIHtsb3dvbmdhbiBrZXJqYX0gb3RoZXIge2xvd29uZ2FuIGtlcmphfX0gZGkge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCBsb3dvbmdhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYjguKHguLUgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYgge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCDguIfguLLguJnguJfguLXguYgge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYjguKHguLUgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYgge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCDguIfguLLguJnguJfguLXguYgge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiVGFtcGlsa2FuIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FuIGtlcmphfSBvbmUge2xvd29uZ2FuIGtlcmphfSBvdGhlciB7bG93b25nYW4ga2VyamF9fSB5YW5nIG1lbmdhbmR1bmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiVGFtcGlsa2FuICB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtsb3dvbmdhbiBrZXJqYX0gb25lIHtsb3dvbmdhbiBrZXJqYX0gb3RoZXIge2xvd29uZ2FuIGtlcmphfX0gZGkge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCBsb3dvbmdhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiVGFtcGlsa2FuIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FuIGtlcmphfSBvbmUge2xvd29uZ2FuIGtlcmphfSBvdGhlciB7bG93b25nYW4ga2VyamF9fSB5YW5nIG1lbmdhbmR1bmcgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9IjoiVGFtcGlsa2FuICB7Y291bnRMYWJlbH0ge2NvdW50LCBwbHVyYWwsID0wIHtsb3dvbmdhbiBrZXJqYX0gb25lIHtsb3dvbmdhbiBrZXJqYX0gb3RoZXIge2xvd29uZ2FuIGtlcmphfX0gZGkge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCBsb3dvbmdhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYjguKHguLUgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYgge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCDguIfguLLguJnguJfguLXguYgge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYjguKHguLUgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPiIsIlNob3cge2NvdW50TGFiZWx9IHtjb3VudCwgcGx1cmFsLCA9MCB7am9ifSBvbmUge2pvYn0gb3RoZXIge2pvYnN9fSBhdCB7Y29tcGFueU5hbWV9Ijoi4LmB4Liq4LiU4LiHIHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fSDguJfguLXguYgge2NvbXBhbnlOYW1lfSIsIjAgam9icyBhdCB7Y29tcGFueU5hbWV9IjoiMCDguIfguLLguJnguJfguLXguYgge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiW8Wg4bipw7bDtsO2xbUge2NvdW50TGFiZWx9IHtjb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5%2FFoX19IMOnw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6zDrMOs4LiB4Li14LmJw6zDrMOs4LiB4Li14LmJxKMgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPl0iLCJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gYXQge2NvbXBhbnlOYW1lfSI6IlvFoOG4qcO2w7bDtsW1IHtjb3VudExhYmVsfSB7Y291bnQscGx1cmFsLD0we8S1w7bDtsO2w599IG9uZXvEtcO2w7bDtsOffSBvdGhlcnvEtcO2w7bDtsOfxaF9fSDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfV0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IlswIMS1w7bDtsO2w5%2FFoSDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gY29udGFpbmluZyB7a2V5d29yZHN9IjoiW8Wg4bipw7bDtsO2xbUge2NvdW50TGFiZWx9IHtjb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn30gb25le8S1w7bDtsO2w599IG90aGVye8S1w7bDtsO2w5%2FFoX19IMOnw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6zDrMOs4LiB4Li14LmJw6zDrMOs4LiB4Li14LmJxKMgPFRleHRMaW5rPntrZXl3b3Jkc308L1RleHRMaW5rPl0iLCJTaG93IHtjb3VudExhYmVsfSB7Y291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0gYXQge2NvbXBhbnlOYW1lfSI6IlvFoOG4qcO2w7bDtsW1IHtjb3VudExhYmVsfSB7Y291bnQscGx1cmFsLD0we8S1w7bDtsO2w599IG9uZXvEtcO2w7bDtsOffSBvdGhlcnvEtcO2w7bDtsOfxaF9fSDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfV0iLCIwIGpvYnMgYXQge2NvbXBhbnlOYW1lfSI6IlswIMS1w7bDtsO2w5%2FFoSDEg8SDxIPhua8ge2NvbXBhbnlOYW1lfV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    