import { getSolSearchDataProp } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  Inline,
  Text,
  TooltipRenderer,
  useResponsiveValue,
} from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { useShouldHideJobCount } from 'src/components/SearchResultPage/SearchResultSummary/ShouldHideJobCountDetector';
import { useSelector, useDispatch } from 'src/store/react';
import { runSearch } from 'src/store/search';
import {
  selectAuthenticated,
  selectCompanySuggestion,
  selectFeatureFlag,
  selectIsResultsInError,
  selectIsResultsLoading,
  selectResults,
  selectSearchQuery,
  selectSolMetadataString,
} from 'src/store/selectors';

import translations from './.vocab';
import SortedBy from './SortedBy/SortedBy';

import * as styleRefs from './SearchResultSummary.css';

const TotalJobsMessageContainer = ({ children }: { children: ReactNode }) => {
  const commonProps: Pick<
    ComponentProps<typeof Box>,
    'id' | 'data' | 'component'
  > = {
    id: 'SearchSummary',
    data: { automation: 'totalJobsMessage' },
    component: 'h1',
  };

  return (
    <Text size="small" {...commonProps}>
      {children}
    </Text>
  );
};

const SearchResultSummary = () => {
  const { t } = useTranslations(translations);

  const searchResultsViewModel = useSelector(selectResults);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const solMetadataString = useSelector(selectSolMetadataString) || '';
  const isError = useSelector(selectIsResultsInError);
  const authenticated = useSelector(selectAuthenticated);
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  const { summary, isZeroResults } = searchResultsViewModel || {};

  const hideJobCount = useShouldHideJobCount();

  const { keywords } = companySuggestion?.search || {};

  const dispatch = useDispatch();

  const lastQuery = useSelector(selectSearchQuery);
  const newToYouTotal =
    useSelector((state) => state.results.totalCountNewToYou) ?? 0;
  const isLoading = useSelector(selectIsResultsLoading);

  const isNewToYou = useSelector((state) => state.search.query.tags) === 'new';
  const setIsNewToYou = (val: boolean) => {
    dispatch(
      runSearch({
        overrideQuery: {
          ...lastQuery,
          tags: val ? 'new' : undefined,
        },
      }),
    );
  };

  let badgeContent: string | null = '';
  if (authenticated && newToYouTotal > 0) {
    if (newToYouTotal > 99) badgeContent = '99+';
    else if (newToYouTotal > 0) badgeContent = newToYouTotal?.toString();
  } else {
    badgeContent = null;
  }

  if (
    isError ||
    ((isZeroResults || isLoading) && !isBehaviouralCuesFiltersEnabled)
  ) {
    return null;
  }

  return (
    <Box
      id="searchResultSummary"
      data-automation="searchResultSummary"
      {...getSolSearchDataProp(solMetadataString)}
    >
      <Box
        id="aria-search-bar"
        aria-live="polite"
        aria-labelledby="SearchSummary"
        role="contentinfo"
        alignItems="center"
        display="flex"
        justifyContent={
          !isBehaviouralCuesFiltersEnabled && hideJobCount
            ? 'flexEnd'
            : 'spaceBetween'
        }
        flexWrap="wrap"
        className={styleRefs.container}
      >
        {!isBehaviouralCuesFiltersEnabled && !hideJobCount && (
          <TotalJobsMessageContainer>
            {t('total jobs count found', {
              displayTotalCount: summary?.displayTotalCount || '0',
              totalCount: Number(summary?.displayTotalCount || 0),
              atCompany: keywords
                ? ` ${t('at {company}', { company: keywords })}`
                : '',
              span: (children: ReactNode) => (
                <span key={0} data-automation="totalJobsCount">
                  {children}
                </span>
              ),
            })}
          </TotalJobsMessageContainer>
        )}
        {isBehaviouralCuesFiltersEnabled && (
          <Box
            boxShadow="borderNeutralLight"
            borderRadius="full"
            paddingLeft={isNewToYou ? 'small' : 'none'}
            paddingRight={isNewToYou ? 'none' : 'small'}
          >
            <Inline space="xsmall" alignY="center">
              <Box
                data={{ automation: 'totalJobsCountBcues' }}
                onClick={() => {
                  setIsNewToYou(false);
                }}
                className={{
                  [styleRefs.filterSelection]: !isNewToYou,
                  [styleRefs.cursorPointer]: isNewToYou,
                }}
              >
                <Text>
                  {`${
                    isMobile
                      ? t('{totalCount} jobs', {
                          displayTotalCount: summary?.displayTotalCount || '0',
                          totalCount: Number(summary?.displayTotalCount || 0),
                        })
                      : t('All {totalCount} jobs', {
                          displayTotalCount: summary?.displayTotalCount || '0',
                          totalCount: Number(summary?.displayTotalCount || 0),
                        })
                  }`}
                </Text>
              </Box>
              <TooltipRenderer
                id="new-to-you-tooltip-renderer"
                placement="bottom"
                tooltip={
                  <Text size="small">{t("Jobs you've not browsed yet")}</Text>
                }
              >
                {({ triggerProps }) => (
                  <Box {...triggerProps} id="new-to-you-filter-text">
                    <Box
                      cursor="pointer"
                      data={{ automation: 'newToYouLabelBcues' }}
                    >
                      <Box
                        onClick={() => {
                          setIsNewToYou(true);
                        }}
                        className={{
                          [styleRefs.filterSelection]: isNewToYou,
                          [styleRefs.cursorPointer]: !isNewToYou,
                        }}
                      >
                        <Inline space="xxsmall" alignY="center">
                          <Text>{isMobile ? t('New') : t('New to you')}</Text>

                          {badgeContent !== null && (
                            <Badge
                              tone="positive"
                              data={{
                                automation: 'newToYouJobsCountBcues',
                              }}
                              bleedY={true}
                            >
                              {badgeContent}
                            </Badge>
                          )}
                        </Inline>
                      </Box>
                    </Box>
                  </Box>
                )}
              </TooltipRenderer>
            </Inline>
          </Box>
        )}
        <SortedBy />
      </Box>
    </Box>
  );
};

export default SearchResultSummary;
