
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJTZWxlY3QgYSBqb2IiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IkRpc3BsYXkgZGV0YWlscyBoZXJlIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJQaWxpaCBsb3dvbmdhbiBrZXJqYSIsIkRpc3BsYXkgZGV0YWlscyBoZXJlIjoiVGFtcGlsa2FuIGRldGFpbCBkaSBzaW5pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJQaWxpaCBsb3dvbmdhbiBrZXJqYSIsIkRpc3BsYXkgZGV0YWlscyBoZXJlIjoiVGFtcGlsa2FuIGRldGFpbCBkaSBzaW5pIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiLguYDguKXguLfguK3guIHguIfguLLguJkiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IuC5geC4quC4lOC4h%2BC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4l%2BC4teC5iOC4meC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiLguYDguKXguLfguK3guIHguIfguLLguJkiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IuC5geC4quC4lOC4h%2BC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4l%2BC4teC5iOC4meC4teC5iCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJQaWxpaCBsb3dvbmdhbiBrZXJqYSIsIkRpc3BsYXkgZGV0YWlscyBoZXJlIjoiVGFtcGlsa2FuIGRldGFpbCBkaSBzaW5pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJQaWxpaCBsb3dvbmdhbiBrZXJqYSIsIkRpc3BsYXkgZGV0YWlscyBoZXJlIjoiVGFtcGlsa2FuIGRldGFpbCBkaSBzaW5pIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiLguYDguKXguLfguK3guIHguIfguLLguJkiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IuC5geC4quC4lOC4h%2BC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4l%2BC4teC5iOC4meC4teC5iCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiLguYDguKXguLfguK3guIHguIfguLLguJkiLCJEaXNwbGF5IGRldGFpbHMgaGVyZSI6IuC5geC4quC4lOC4h%2BC4o%2BC4suC4ouC4peC4sOC5gOC4reC4teC4ouC4lOC4l%2BC4teC5iOC4meC4teC5iCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJbxaDhur3hur3hur3GmuG6veG6veG6vcOn4bmvIMSDxIPEgyDEtcO2w7bDtsOfXSIsIkRpc3BsYXkgZGV0YWlscyBoZXJlIjoiW%2BG4isOsw6zFocalxprEg8SDw73DvSDGjOG6veG6veG5r8SDxIPDrMOsxprFoSDhuKnhur3hur3FmeG6veG6vV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWxlY3QgYSBqb2IiOiJbxaDhur3hur3hur3GmuG6veG6veG6vcOn4bmvIMSDxIPEgyDEtcO2w7bDtsOfXSIsIkRpc3BsYXkgZGV0YWlscyBoZXJlIjoiW%2BG4isOsw6zFocalxprEg8SDw73DvSDGjOG6veG6veG5r8SDxIPDrMOsxprFoSDhuKnhur3hur3FmeG6veG6vV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    