
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlBhcnRuZXIgc2l0ZXMiLCJSZWZpbmUgYnkgc3ViLWNsYXNzaWZpY2F0aW9ucyI6IlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiUmVmaW5lIGJ5IGxvY2F0aW9uIiwiSm9icyBpbiB7c3VidXJifSI6IkpvYnMgaW4ge3N1YnVyYn0iLCJGaW5kIG1vcmUuLi4iOiJGaW5kIG1vcmUgPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiBvbiBKb3JhIiwiRmluZCBtb3JlIG9uIEpvcmEiOiJGaW5kIG1vcmUgb24gSm9yYSIsIlJlYWQgTW9yZSI6IlJlYWQgTW9yZSIsIlJlYWQgTGVzcyI6IlJlYWQgTGVzcyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlNpdHVzIG1pdHJhIiwiUmVmaW5lIGJ5IHN1Yi1jbGFzc2lmaWNhdGlvbnMiOiJGaWx0ZXIgYmVyZGFzYXJrYW4gc3ViLWtsYXNpZmlrYXNpIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiRmlsdGVyIGJlcmRhc2Fya2FuIGxva2FzaSIsIkpvYnMgaW4ge3N1YnVyYn0iOiJMb3dvbmdhbiBkaSB7c3VidXJifSIsIkZpbmQgbW9yZS4uLiI6IlRlbXVrYW4gbGViaWggYmFueWFrIDxUZXh0TGluaz57bGFiZWx9PC9UZXh0TGluaz4gZGkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoiVGVtdWthbiBzZWxlbmdrYXBueWEgZGkgSm9yYSIsIlJlYWQgTW9yZSI6IkJhY2EgU2VsZW5na2FwbnlhIiwiUmVhZCBMZXNzIjoiQmFjYSBMZWJpaCBTZWRpa2l0In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlNpdHVzIG1pdHJhIiwiUmVmaW5lIGJ5IHN1Yi1jbGFzc2lmaWNhdGlvbnMiOiJGaWx0ZXIgYmVyZGFzYXJrYW4gc3ViLWtsYXNpZmlrYXNpIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiRmlsdGVyIGJlcmRhc2Fya2FuIGxva2FzaSIsIkpvYnMgaW4ge3N1YnVyYn0iOiJMb3dvbmdhbiBkaSB7c3VidXJifSIsIkZpbmQgbW9yZS4uLiI6IlRlbXVrYW4gbGViaWggYmFueWFrIDxUZXh0TGluaz57bGFiZWx9PC9UZXh0TGluaz4gZGkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoiVGVtdWthbiBzZWxlbmdrYXBueWEgZGkgSm9yYSIsIlJlYWQgTW9yZSI6IkJhY2EgU2VsZW5na2FwbnlhIiwiUmVhZCBMZXNzIjoiQmFjYSBMZWJpaCBTZWRpa2l0In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IuC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4nuC4seC4meC4mOC4oeC4tOC4leC4oyIsIlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4Lii4LmI4Lit4LiiIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZIiwiSm9icyBpbiB7c3VidXJifSI6IuC4h%2BC4suC4meC5g%2BC4mXtzdWJ1cmJ9IiwiRmluZCBtb3JlLi4uIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiDguJrguJkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Lia4LiZIEpvcmEiLCJSZWFkIE1vcmUiOiLguK3guYjguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJSZWFkIExlc3MiOiLguKLguYjguK3guKXguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IuC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4nuC4seC4meC4mOC4oeC4tOC4leC4oyIsIlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4Lii4LmI4Lit4LiiIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZIiwiSm9icyBpbiB7c3VidXJifSI6IuC4h%2BC4suC4meC5g%2BC4mXtzdWJ1cmJ9IiwiRmluZCBtb3JlLi4uIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiDguJrguJkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Lia4LiZIEpvcmEiLCJSZWFkIE1vcmUiOiLguK3guYjguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJSZWFkIExlc3MiOiLguKLguYjguK3guKXguIcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlNpdHVzIG1pdHJhIiwiUmVmaW5lIGJ5IHN1Yi1jbGFzc2lmaWNhdGlvbnMiOiJGaWx0ZXIgYmVyZGFzYXJrYW4gc3ViLWtsYXNpZmlrYXNpIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiRmlsdGVyIGJlcmRhc2Fya2FuIGxva2FzaSIsIkpvYnMgaW4ge3N1YnVyYn0iOiJMb3dvbmdhbiBkaSB7c3VidXJifSIsIkZpbmQgbW9yZS4uLiI6IlRlbXVrYW4gbGViaWggYmFueWFrIDxUZXh0TGluaz57bGFiZWx9PC9UZXh0TGluaz4gZGkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoiVGVtdWthbiBzZWxlbmdrYXBueWEgZGkgSm9yYSIsIlJlYWQgTW9yZSI6IkJhY2EgU2VsZW5na2FwbnlhIiwiUmVhZCBMZXNzIjoiQmFjYSBMZWJpaCBTZWRpa2l0In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IlNpdHVzIG1pdHJhIiwiUmVmaW5lIGJ5IHN1Yi1jbGFzc2lmaWNhdGlvbnMiOiJGaWx0ZXIgYmVyZGFzYXJrYW4gc3ViLWtsYXNpZmlrYXNpIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoiRmlsdGVyIGJlcmRhc2Fya2FuIGxva2FzaSIsIkpvYnMgaW4ge3N1YnVyYn0iOiJMb3dvbmdhbiBkaSB7c3VidXJifSIsIkZpbmQgbW9yZS4uLiI6IlRlbXVrYW4gbGViaWggYmFueWFrIDxUZXh0TGluaz57bGFiZWx9PC9UZXh0TGluaz4gZGkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoiVGVtdWthbiBzZWxlbmdrYXBueWEgZGkgSm9yYSIsIlJlYWQgTW9yZSI6IkJhY2EgU2VsZW5na2FwbnlhIiwiUmVhZCBMZXNzIjoiQmFjYSBMZWJpaCBTZWRpa2l0In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IuC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4nuC4seC4meC4mOC4oeC4tOC4leC4oyIsIlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4Lii4LmI4Lit4LiiIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZIiwiSm9icyBpbiB7c3VidXJifSI6IuC4h%2BC4suC4meC5g%2BC4mXtzdWJ1cmJ9IiwiRmluZCBtb3JlLi4uIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiDguJrguJkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Lia4LiZIEpvcmEiLCJSZWFkIE1vcmUiOiLguK3guYjguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJSZWFkIExlc3MiOiLguKLguYjguK3guKXguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IklEIiwiUGFydG5lciBzaXRlcyI6IuC5gOC4p%2BC5h%2BC4muC5hOC4i%2BC4leC5jOC4nuC4seC4meC4mOC4oeC4tOC4leC4oyIsIlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZ4Lii4LmI4Lit4LiiIiwiUmVmaW5lIGJ5IGxvY2F0aW9uIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZIiwiSm9icyBpbiB7c3VidXJifSI6IuC4h%2BC4suC4meC5g%2BC4mXtzdWJ1cmJ9IiwiRmluZCBtb3JlLi4uIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04LihPFRleHRMaW5rPntsYWJlbH08L1RleHRMaW5rPiDguJrguJkgSm9yYSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoi4LiE4LmJ4LiZ4Lir4Liy4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Lia4LiZIEpvcmEiLCJSZWFkIE1vcmUiOiLguK3guYjguLLguJnguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJSZWFkIExlc3MiOiLguKLguYjguK3guKXguIcifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IlvDj%2BG4il0iLCJQYXJ0bmVyIHNpdGVzIjoiW8akxIPEg8SDxZnhua%2FguIHguLXguYnhur3hur3hur3FmSDFocOsw6zDrOG5r%2BG6veG6veG6vcWhXSIsIlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIjoiW8WY4bq94bq94bq9xpLDrMOsw6zguIHguLXguYnhur3hur3hur0gw5%2FDvcO9w70gxaHHmseax5rDny3Dp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJSZWZpbmUgYnkgbG9jYXRpb24iOiJbxZjhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw5%2FDvcO9IMaaw7bDtsOnxIPEg%2BG5r8Osw6zDtsO24LiB4Li14LmJXSIsIkpvYnMgaW4ge3N1YnVyYn0iOiJbxLTDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iSB7c3VidXJifV0iLCJGaW5kIG1vcmUuLi4iOiJbxpHDrMOsw6zguIHguLXguYnGjCBtzILDtsO2w7bFmeG6veG6veG6vSA8VGV4dExpbms%2Be2xhYmVsfTwvVGV4dExpbms%2BIMO2w7bDtuC4geC4teC5iSDEtMO2w7bDtsWZxIPEg8SDXSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoiW8aRw6zDrMOs4LiB4Li14LmJxowgbcyCw7bDtsO2xZnhur3hur3hur0gw7bDtsO24LiB4Li14LmJIMS0w7bDtsO2xZnEg8SDxINdIiwiUmVhZCBNb3JlIjoiW8WY4bq94bq9xIPEg8aMIOG5gsO2w7bFmeG6veG6vV0iLCJSZWFkIExlc3MiOiJbxZjhur3hur3hur3Eg8SDxIPGjCDFgeG6veG6veG6vcWhxaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJRCI6IlvDj%2BG4il0iLCJQYXJ0bmVyIHNpdGVzIjoiW8akxIPEg8SDxZnhua%2FguIHguLXguYnhur3hur3hur3FmSDFocOsw6zDrOG5r%2BG6veG6veG6vcWhXSIsIlJlZmluZSBieSBzdWItY2xhc3NpZmljYXRpb25zIjoiW8WY4bq94bq94bq9xpLDrMOsw6zguIHguLXguYnhur3hur3hur0gw5%2FDvcO9w70gxaHHmseax5rDny3Dp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJSZWZpbmUgYnkgbG9jYXRpb24iOiJbxZjhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw5%2FDvcO9IMaaw7bDtsOnxIPEg%2BG5r8Osw6zDtsO24LiB4Li14LmJXSIsIkpvYnMgaW4ge3N1YnVyYn0iOiJbxLTDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iSB7c3VidXJifV0iLCJGaW5kIG1vcmUuLi4iOiJbxpHDrMOsw6zguIHguLXguYnGjCBtzILDtsO2w7bFmeG6veG6veG6vSA8VGV4dExpbms%2Be2xhYmVsfTwvVGV4dExpbms%2BIMO2w7bDtuC4geC4teC5iSDEtMO2w7bDtsWZxIPEg8SDXSIsIkZpbmQgbW9yZSBvbiBKb3JhIjoiW8aRw6zDrMOs4LiB4Li14LmJxowgbcyCw7bDtsO2xZnhur3hur3hur0gw7bDtsO24LiB4Li14LmJIMS0w7bDtsO2xZnEg8SDxINdIiwiUmVhZCBNb3JlIjoiW8WY4bq94bq9xIPEg8aMIOG5gsO2w7bFmeG6veG6vV0iLCJSZWFkIExlc3MiOiJbxZjhur3hur3hur3Eg8SDxIPGjCDFgeG6veG6veG6vcWhxaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    